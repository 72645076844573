form {width:95%;}
form p.info {font-size:var(--font-size-sxx);text-align:center;margin-bottom:2vw;}
textarea {font-size:var(--font-size-sx);min-height:18vw;}
select option {background:var(--color-brd);color:var(--color-wht-60);text-shadow:none;border:none;}
select option:not(:checked) {background-color:var(--color-brd);}
input.sm {width:96%;}
button {text-align:left;background:var(--color-yel-60);position:relative;font-size:var(--font-size-sy);font-family:var(--font-capt);color:var(--color-nav);font-weight:400;box-shadow:0px 0px 3px var(--color-drk-60);text-shadow:var(--text-shadow);text-transform:uppercase;min-width:160px;white-space:nowrap;border:0;border-bottom:solid 1px var(--color-nav);border-top:solid 3px var(--color-bg);padding:.5vw 5vw .4vw 1vw;border-radius:var(--field-border-radius);cursor:pointer;transition:var(--transition-but);}
button svg {filter:var(--svg-shadow);position:absolute;right:.8vw;width:12%;top:3px;min-width:20px;max-width:30px;fill:var(--color-nav);transition:var(--transition-but);}
button:hover {background:var(--color-yel);box-shadow:0px 0px 9px var(--color-wht-60);}
button.grn {background:var(--color-grn-80) !important;color:var(--color-wht-80);border-radius:var(--field-border-radius);border-top:solid 1px var(--color-nav);border-bottom:solid 2px var(--color-gry);padding:.8vw 5vw .6vw 1vw;}
button.grn svg {fill:var(--color-wht-80);width:2vw;top:.4vw;}
button.grn:hover {color:var(--color-yel);background:var(--color-grn);box-shadow:0px 0px 3px var(--color-txt-80);border-top:solid 1px var(--color-grn);border-bottom:solid 2px var(--color-grn);}
button.grn:hover svg {fill:var(--color-yel);}
button.yel {background:var(--color-yel-80) !important;color:var(--color-grn-80);border-radius:var(--field-border-radius);border-top:solid 1px var(--color-grn);border-bottom:solid 2px var(--color-grn);padding:.8vw 5vw .6vw 1vw;}
button.yel svg {filter:drop-shadow( 3px 3px 2px rgba(255, 255, 255, 1));fill:var(--color-grn-80);}
button.yel svg.sms {width:2.3vw !important;max-width:32px;}
button.yel:hover {color:var(--color-grn);background:var(--color-yel);box-shadow:0px 0px 9px var(--color-txt-80);border-top:solid 1px var(--color-grn);border-bottom:solid 2px var(--color-grn);}
button.yel:hover svg {fill:var(--color-grn);}
button.gry {background:var(--color-gry-80) !important;color:var(--color-wht-80);border-radius:var(--field-border-radius);border-top:solid 1px var(--color-grn);border-bottom:solid 2px var(--color-drk-90);padding:.8vw 5vw .6vw 1vw;}
button.gry svg {fill:var(--color-wht-90);}
button.gry:hover {color:var(--color-yel);background:var(--color-gry);box-shadow:0px 0px 9px var(--color-txt-80);border-top:solid 1px var(--color-drk);border-bottom:solid 2px var(--color-drk);}
button.gry:hover svg {fill:var(--color-yel);}
button.red {background:var(--color-red-80) !important;color:var(--color-wht-80);border-radius:var(--field-border-radius);border-top:solid 1px var(--color-grn);border-bottom:solid 2px var(--color-grn);padding:.8vw 5vw .6vw 1vw;}
button.red svg {fill:var(--color-wht-80);}
button.sms {padding:10px;}
button.sms span {float:left}
button.sms b {float:right;}
button.deli {font-size:var(--font-size-sx);padding:.5vw 3vw .5vw 1.5vw !important;width:24%;max-width:220px;text-shadow:0px 0px 3px var(--clr-drk-60);border-top:solid 2px var(--color-gry);border-bottom:solid 2px var(--color-gry);}
button.deli svg {width:12%;top:3px;right:1vw;fill:var(--color-wht-80);}
button.deli:hover {text-shadow:0px 1px 5px var(--color-yel-80);color:var(--color-wht-80);border-top:solid 2px var(--color-gry);border-bottom:solid 2px var(--color-gry);}
button.deli:hover svg {width:12%;top:3px;right:1vw;fill:var(--color-wht);}
button.full {font-size:var(--font-size-sx);padding:.5vw 3vw .5vw 1.5vw !important;width:24%;max-width:220px;text-shadow:0px 3px 8px #000;border-top:solid 2px var(--color-blu-80);border-bottom:none;}
button.full svg {width:12%;top:3px;right:1.2vw;fill:var(--color-wht-60);}
button.full:hover {text-shadow:0px 1px 2px var(--color-yel);color:var(--color-wht-80);border-top:solid 2px var(--color-gry);border-bottom:solid 2px var(--color-gry);}
button.full:hover svg {width:12%;top:3px;right:1.2vw;fill:var(--color-wht);}
button.filter {font-size:var(--font-size-sx);padding:.5vw 3vw .5vw 1.5vw !important;width:24%;max-width:220px;text-shadow:0px 0px 3px var(--clr-drk-60);border-top:solid 2px var(--color-gry);border-bottom:solid 2px var(--color-gry);}
button.filter svg {width:12%;top:3px;right:1vw;fill:var(--color-wht-80);}
button.filter:hover {text-shadow:0px 1px 5px var(--color-yel-80);color:var(--color-wht-80);border-top:solid 2px var(--color-gry);border-bottom:solid 2px var(--color-gry);}
button.filter:hover svg {width:12%;top:3px;right:1vw;fill:var(--color-wht);}
button.adrs {font-size:var(--font-size-sx);padding:.5vw 3vw .5vw 1.5vw;width:24%;max-width:220px;text-shadow:0px 0px 3px var(--clr-drk-60);border-top:solid 2px var(--color-gry);border-bottom:solid 2px var(--color-gry);}
button.adrs svg {width:12%;top:3px;right:1vw;fill:var(--color-wht-80);}
button.adrs:hover {padding:.5vw 3vw .5vw 1.5vw;text-shadow:0px 1px 5px var(--color-yel-80);color:var(--color-wht-80);border-top:solid 2px var(--color-gry);border-bottom:solid 2px var(--color-gry);}
button.adrs:hover svg {width:12%;top:3px;right:1vw;fill:var(--color-wht);}

button svg.plus {width:1.8vw;}
button.more {text-align:center;padding:.5vw 2vw;}
button[disabled] {background:none;opacity:.6;}
button.app-installer {z-index:2000;font-size:var(--font-size-sy);text-align:center;padding:.2vw 2vw .2vw;position:fixed;bottom:3vw;left:2vw;}
button.whatsapp {display:none;z-index:1000;font-size:var(--font-size-sm);text-transform:none;text-align:center;width:50px;height:53px;min-width:50px;max-width:60px;border-radius:50%;padding:.3vw 0 0;position:fixed;bottom:3vw;right:2vw;}
button.whatsapp svg {position:relative;width:40px;top:auto;right:auto;}
button.whatsapp a:link, button.whatsapp a:visited {color:var(--color-wht-80);}
button.whatsapp:hover a {color:var(--color-yel-80);}
label {position:absolute;left:.5vw;top:0;cursor:pointer;padding:0;font-size:var(--font-size-sy);color:var(--color-nav);transition:var(--transition-but);}
label.active {color:var(--color-red);}
label svg {filter:var(--svg-shadow);display:none;}
legend {position:absolute;left:.5vw;top:-.2vw;padding:0;font-size:var(--font-size-sy);color:var(--color-txt-60);}
legend svg {filter:var(--svg-shadow);position:absolute;right:-3vw;top:-.5vw;width:2vw;cursor:pointer;}
input:not(.range),
textarea, 
select {box-sizing:border-box;font-size:var(--font-size-sm) !important;width:100%;margin-bottom:1vh;color:var(--color-txt);border:0;border-bottom:solid 1px var(--color-nav);border-top:solid 3px var(--color-bg);border-radius:0px 0px var(--field-border-radius) var(--field-border-radius);transition:var(--transition-but);}
input:not(.range):focus,
select:focus, 
textarea:focus { background:var(--color-yel-20);outline: none !important;border:solid 1px var(--color-nav);border-radius:var(--field-border-radius);border-top:solid 3px var(--color-nav);box-shadow:inset 0px 3px 16px var(--color-nav-40);}
input:not(.range):focus ~ label, 
select:focus ~ label, 
textarea:focus ~ label {color:var(--color-red);top:-1.5vw;}
fieldset {position:relative;width:100%;min-width:240px;max-width:620px;border:0;box-shadow:0;margin:1.8vw auto 1.5vw;}
fieldset textarea {background:var(--color-blh-20);font-size:var(--font-size-sx);width:100%;color:var(--color-txt-80);border:0;border-bottom:solid 1px var(--color-nav);padding:1.8vw 1vw .6vw;border-radius:var(--field-border-radius);}
fieldset textarea:focus {background:var(--color-yel-20);border:0;border-bottom:solid 1px var(--color-nav);}
fieldset select {background-color:var(--color-blh-20);font-size:var(--font-size-sx);width:100%;color:var(--color-txt);border:0;border-bottom:solid 1px var(--color-nav);padding:1.8vw 1vw .6vw;border-radius:var(--field-border-radius);}
fieldset select:focus {background-color:var(--color-yel-20);color:var(--color-txt);border:0;border-bottom:solid 1px var(--color-nav);}
fieldset select:focus option {color:var(--color-txt);}
fieldset input:not(.range) {background:var(--color-blh-20);font-size:var(--font-size-sx);width:100%;color:var(--color-txt-80);border:0;border-bottom:solid 1px var(--color-nav);padding:1.8vw 1vw .6vw;border-radius:var(--field-border-radius);}
fieldset input:not(.range):focus {background:var(--color-yel-20);font-size:var(--font-size-sx);border:0;border-bottom:solid 1px var(--color-nav);}
fieldset input[type=checkbox],
fieldset input[type=radio] {max-width:14px;}
fieldset input[type=range] {display:none;accent-color: var(--color-grn-40);}
fieldset input[type=text] {padding-right:2vw;}
fieldset input[name=fio] {text-transform:capitalize;}
fieldset input[type=file] {position:absolute;padding:1.7vw 3% .3vw !important;}
fieldset input[type=file]:focus {padding:1.7vw 3% .1vw;}
fieldset input[type=number] {padding:1.8vw .2vw .8vw;box-sizing:border-box;font-family:var(--font-crr);font-size:var(--font-size-sm) !important;font-weight:600;text-align:center;}
fieldset input[type=number]:focus {padding:1.8vw 2vw .8vw;font-size:var(--font-size-ss);text-align:center !important;}
fieldset label span {float:right;}
fieldset.radio-wrap {box-sizing:border-box;padding-right:1vw;border-bottom:solid 1px var(--color-nav);display:flex;flex-wrap:nowrap;justify-content:space-between;align-items:center;background:none;}
fieldset.radio-wrap label {text-align:left;width:90%;position:relative;white-space:nowrap;}
fieldset.radio-wrap legend {color:var(--color-txt);}
fieldset.radio-wrap div {width:48%;display:flex;flex-wrap:nowrap;justify-content:space-between;margin:1vw .3vw 0;}
fieldset.radio-wrap div span {max-width:30px;margin-left:1vw;}
fieldset.radio-wrap .fix-bottom {padding-top:4vw !important;}
fieldset.no-flex {width:80%;max-width:650px;position:relative;display:block;margin:1vw auto;}
fieldset.no-flex div {white-space:nowrap;border-bottom:0;display:flex;justify-content:space-between;}
fieldset.no-flex input {margin-top:.4vw;width:15px;position:absolute;right:.5vw;}
fieldset.ready label {color:var(--color-grn);top:0;width:95%;}
fieldset.ready label svg.check {display:block;position:absolute;right:0;top:1.8vw;width:1.5vw;fill:var(--color-grn-80);}
fieldset.ready label svg.edit {display:block;position:absolute;right:-.5vw;top:1.5vw;width:1.8vw;fill:var(--color-grn-80);}
fieldset.ready label svg.close {display:block;position:absolute;right:0;top:1.8vw;width:1.5vw;fill:var(--color-red-80);}
fieldset.ready textarea {border-bottom:solid 1px var(--color-grn);background:none;}
fieldset.ready input {border-bottom:solid 1px var(--color-grn);background:none;text-align:left;}
fieldset.ready input[type=number] {text-align:center;}
fieldset.error label {color:var(--color-red);top:0;width:95%;}
fieldset.error label svg.close {display:block;position:absolute;right:0;top:1.4vw;width:1.8vw;fill:var(--color-red);}
fieldset.error input {border-bottom:solid 1px var(--color-red);background:var(--color-red-20);}
fieldset.bg-green {background:none;}
fieldset input[type=range] {margin:none !important;border-bottom:none;border-top:none;padding:none !important;border-radius:none;}
fieldset input[type=range]:focus {margin:none !important;border-bottom:none;border-top:none;padding:none !important;border-radius:none;}
fieldset aside.error-field {display:none;z-index:2;position:absolute;top:-5px;right:0;left:0;background:var(--color-red);color:var(--color-wht);font-size:var(--font-size-sy);padding:2px 5px;}
fieldset aside.error-active {display:block;}
fieldset input:focus ~ aside.error-active {display:none;}
fieldset.grn {border-bottom:0;}
fieldset.grn input {display:none;}
fieldset.grn div {background:var(--color-grn-20);padding:5px;margin-top:0;font-size:var(--font-size-sw);border-radius:var(--field-border-radius);}
fieldset.grn div.active {background:var(--color-grn-80);}
fieldset.grn div.active label {color:var(--color-wht-80);}
fieldset.grn div.disabled {opacity:.5;}
fieldset.disabled {opacity:.3;}
fieldset aside.check {z-index:-1;position:absolute;opacity:0;right:20vw;top:1.6vw;font-size:var(--font-size-sl);color:var(--color-red);transition:var(--transition-but);cursor:pointer;}
fieldset aside.check-active {z-index:1;opacity:1;right:6vw;}
datalist {background:var(--color-blu-20);}

meter {width:100%;}
meter::-webkit-meter-bar {background: var(--color-gry);}
meter::-webkit-meter-optimum-value {background: var(--color-grn);}
meter::-webkit-meter-suboptimum-value {background: var(--color-blu);}
meter::-webkit-meter-even-less-good-value {background: var(--color-yel);}

#form-auth {display:flex;justify-content:space-around;align-items:flex-start;position:absolute;width:100%;height:100%;overflow-y:auto;}
#form-auth div.mod-comp-wrap {background:var(--color-wht-80);width:35%;min-width:400px;margin-top:5vw;}
#form-auth form {width:100%;max-width:1400px;margin:3vw auto 1vw;} 
#form-auth .flex {margin:2vw auto 1vw;width:90%;} 
#form-auth fieldset {width:90%;}
#form-auth div.flex {justify-content:space-around;}

#form-order {width:100%;}
#form-order h3.info {width:100%;text-transform:none;color:var(--color-grn-80);text-align:center;margin:2vw auto 3vw;}
#form-order div.mod-comp-wrap {width:90%;max-width:1200px;margin-top:1.5vw;}
#form-order form {margin:2vw auto 0;padding:0;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;}
#form-order form.disabled {opacity:.3;}
#form-order form fieldset.disabled {opacity:.3;}
#form-order fieldset {width:100%;max-width:600px;margin:0 1vw 1vw;}
#form-order fieldset.w-50  {width:47%;}
#form-order div.flex {width:100%;margin:auto;display:flex;justify-content:space-between;}
#form-order fieldset.sm {margin:0 0 1vw;width:47%;min-width:160px;max-width:350px;}
#form-order fieldset.sm input {margin-bottom:1vw;width:100%;}
#form-order fieldset.sm input[type=number] {padding:2vw 1vw 1vw;}
#form-order fieldset.sm input.client {padding:2vw 1vw 1vw;}
#form-order fieldset.mb-2 {margin-bottom:2vw;}
#form-order fieldset.mt-2 {margin-top:2vw;}
#form-order fieldset.mt-3 {margin-top:1.5vw;}
#form-order .fieldset-wrap {justify-content:flex-end;margin:0;width:47%;min-width:300px;max-width:600px;}
#form-order .fieldset-wrap label {width:95%;}
#form-order .fieldset-wrap input {width:100%;}
#form-order .fieldset-wrap select {width:97%;}
#form-order fieldset.radio-wrap {padding-top:2vw;margin-bottom:2vw;}
#form-order fieldset.long-wrap textarea {min-height:18vw;}
#form-order fieldset legend {padding:.3vw 0 0 .5vw;}
#form-order fieldset.short {width:31.5%;max-width:400px;}
#form-order fieldset.short div {width:45%;max-width:400px;}
#form-order fieldset.long {width:64%;min-width:350px;max-width:700px;margin-right:0;}
#form-order fieldset.long div.w-30 {width:35%;max-width:300px;}
#form-order fieldset.long div.w-70 {width:59%;max-width:500px;}
#form-order fieldset.tarif {width:100%;max-width:500px;}
#form-order fieldset.tarif div {width:30%;}

#form-order div.order-ready-info {width:100%;display:flex;flex-wrap:wrap;justify-content:space-around;}
#form-order div.order-ready-info h3 {width:100%;display:block;margin:0 auto 2vw 1vw;}
#form-order div.order-ready-info ul {width:31%;list-style-position:inside;margin-bottom:3vw;}
#form-order div.order-ready-info ul li {font-size:var(--font-size-sl);color:var(--color-drk-80);width:100%;display:flex;justify-content:space-between;align-items:flex-end;margin-bottom:.5vw;overflow:hidden;}
#form-order div.order-ready-info ul li b {color:var(--color-nav);font-size:var(--font-size-sl);text-shadow:none;font-weight:400;white-space:nowrap;margin:0 0 .3vw auto;}
#form-order div.order-ready-info ul li i {width:100%;height:1vw;border-bottom:dotted 1px var(--color-gry);margin:0 -20vw .2vw .2vw;overflow:hidden;}
#form-order div.order-ready-info ul li.disabled {opacity:.3;}

#form-order fieldset.category-wrap {width:95%;max-width:1200px;margin:2vw auto 3vw;overflow:hidden;overflow-x:auto;}
#form-order fieldset.category-wrap ul {display:flex;flex-wrap:nowrap;justify-content:space-between;overflow:hidden;overflow-x:auto;}
#form-order fieldset.category-wrap ul li {width:9%;min-width:92px;font-size:var(--font-size-sx);color:var(--color-drk-80);background:var(--color-slv-30);border:solid 1px var(--color-nav-60);border-radius:var(--main-border-radius);font-weight:600;margin:.4vw;padding:.5vw 0;list-style-type:none;text-align:center;text-transform:uppercase;cursor:pointer;}
#form-order fieldset.category-wrap ul li:hover {background:var(--color-yel-30);box-shadow:0px 0px 9px var(--color-gry-60);color:var(--color-nav);}
#form-order fieldset.category-wrap ul li.active {opacity:1;background:var(--color-grn-20);box-shadow:0px 0px 9px var(--color-gry-60);color:var(--color-red-80);}
#form-order fieldset.category-wrap ul li img {width:50%;margin-bottom:1vw;}
#form-order fieldset.category-wrap div.track-ready {width:100%;padding:3vw 2vw 0;box-sizing:border-box;}
#form-order fieldset.category-wrap ul.disabled li:hover {opacity:.6;color:var(--color-drk-80);background:var(--color-blu-30);border:solid 1px var(--color-nav-60);}
#form-order fieldset.category-ready li {opacity:.6;}
#form-order fieldset.category-ready li:hover {opacity:1;box-shadow:none;}
#form-order fieldset.category-ready ul.disabled li:hover {opacity:.6;box-shadow:none;}
#form-order fieldset.category-ready ul.disabled li.active:hover {opacity:1;background:var(--color-grn-30);box-shadow:0px 0px 9px var(--color-gry-60);color:var(--color-red-80);}
#form-order fieldset.toggle-wrap {display:flex;justify-content:space-around;width:100%;max-width:1200px;margin:0 auto 1vw;}
    div.price-wrap {display:block;margin:0 auto;width:90%;min-width:350px;max-width:1250px;padding:3vw 0;box-sizing:border-box;transition:all 1s ease 0s;}
    div.price-wrap div.dtable {width:100%;font-size:var(--font-size-ss);background:var(--color-wht-80);border-radius:var(--main-border-radius);box-shadow:var(--lght-shadow);}
    div.price-wrap div.drow {cursor:pointer;}
    div.price-wrap div.drow:last-child div.dcell {border-bottom:none;padding-bottom:1vw;}
    div.price-wrap div.dhrow {display:table-row;}
    div.price-wrap div.dhead {font-family:var(--font-head);background:var(--color-grn-80);text-align:center;white-space:nowrap;color:var(--color-wht-80);font-weight:800;border-bottom:none;text-shadow:0px 0px 3px var(--color-drk);padding:1vw 1vw 1vw 1vw;font-size:var(--font-size-mx);padding-top:1vw;}
    div.price-wrap div.dhead:nth-child(1) {border-radius:var(--main-border-radius) 0 0 0;text-align:left;padding-left:5vw;}
    div.price-wrap div.dhead:nth-child(2) {width:20%;}
    div.price-wrap div.dhead:nth-child(3) {width:20%;}
    div.price-wrap div.dhead:nth-child(4) {border-radius:0 var(--main-border-radius) 0 0;width:15px;padding-right:5vw;}
    div.price-wrap div.dcell {font-family:var(--font-bold);text-align:center;align-content:center;color:var(--color-drk-40);white-space:nowrap;overflow:hidden;text-overflow:ellipsis;border-bottom:solid 1px var(--color-blu);padding:.5vw 1vw;}
    div.price-wrap div.dcell:nth-child(1) {text-align:left;padding-left:5vw;color:var(--color-nav-60);}
    div.price-wrap div.dcell:nth-child(1) svg {fill:var(--color-nav-60);width:1.2vw;margin-right:1vw;}
    div.price-wrap div.dcell:nth-child(4) {padding-right:5vw;}
    div.price-wrap div.dcell:nth-child(5) svg {fill:var(--color-drk-40);}
    div.price-wrap div.dcell mark {padding:.2vw .4vw;background:var(--color-grn-20);color:var(--color-txt-80);font-size:.7em;}
    div.price-wrap div.dactv {background:var(--color-yel-40);}
    div.price-wrap div.dactv div {font-weight:800;background:none;color:var(--color-drk-80) !important;}
    div.price-wrap div.dactv svg {fill:var(--color-grn-80) !important;transform:scale(1.2);}
    div.price-wrap div.dactv mark {padding:.2vw .4vw;background:var(--color-grn-80);color:var(--color-yel);font-size:.8em;font-weight:400;text-shadow:0px 0px 3px var(--color-drk);}
    div.price-wrap div.dactv mark svg {width:1.2vw;fill:var(--color-yel);}
    div.price-wrap div.drow:hover svg {fill:var(--color-grn-80) !important;}
    div.price-wrap div.calc-result {width:100%;margin:0 auto 2vw;display:flex;flex-wrap:wrap;justify-content:space-between;}
    div.price-wrap div.calc-result ul {list-style-position:inside;margin-bottom:0;padding-bottom:1vw;background:var(--color-wht-80);border-radius:var(--main-border-radius);box-shadow:var(--lght-shadow);}
    div.price-wrap div.calc-result ul:nth-child(1) {width:25%;}
    div.price-wrap div.calc-result ul:nth-child(2) {width:35%;}
    div.price-wrap div.calc-result ul:nth-child(3) {width:34%;}
    div.price-wrap div.calc-result ul li {font-size:var(--font-size-sl);color:var(--color-drk-80);width:96%;display:flex;justify-content:space-between;align-items:flex-end;margin:0 auto .5vw;padding:0 .5vw;box-sizing:border-box;overflow:hidden;}
    div.price-wrap div.calc-result ul li b {min-width:32%;display:flex;justify-content:space-between;color:var(--color-nav);font-size:1.2em !important;text-shadow:none;font-weight:400;white-space:nowrap;margin:0 0 .3vw auto;}
    div.price-wrap div.calc-result ul li b.red {color:var(--color-red-80);}
    div.price-wrap div.calc-result ul li b mark {padding:.2vw .4vw;background:var(--color-grn-20);color:var(--color-txt-80);font-size:.9em;}
    div.price-wrap div.calc-result ul li b.price {color:var(--color-grn);}
    div.price-wrap div.calc-result ul li b svg {width:1.5vw;min-width:18px;fill:var(--color-drk-80);}
    div.price-wrap div.calc-result ul li b small {font-size:.9em;color:var(--color-drk-80);}
    div.price-wrap div.calc-result ul li i {width:80%;height:1vw;border-bottom:dotted 1px var(--color-gry);margin:0 auto;overflow:hidden;}
    div.price-wrap div.calc-result ul li span {white-space:nowrap;margin-bottom:.1vw;}
    div.price-wrap div.calc-result ul li span mark {padding:.3vw .5vw;background:var(--color-blu);color:var(--color-wht-80);margin-left:.5vw;font-size:1em;}
    div.price-wrap div.calc-result ul li span.caption {font-weight:300;color:var(--color-txt);}
    div.price-wrap div.calc-result ul li.disabled {opacity:.3;}
    div.price-wrap div.calc-result svg.await {width:1.5vw;min-width:20px;max-width:28px;margin-bottom:-.4vw;fill:var(--color-grn);}
    div.price-wrap div.calc-result ul b.client {text-transform:capitalize;font-weight:500;}
    div.price-wrap div.calc-result div.left {width:30%;}
    div.price-wrap div.calc-result div.rght {width:62%;}
    div.price-wrap div.calc-result h3 {margin-bottom:2vw;font-size:var(--font-size-sl);background:var(--color-grn-80);color:var(--color-wht-80);border-radius:var(--main-border-radius) var(--main-border-radius) 0 0;padding:1vw;margin-bottom:1vw;box-sizing:border-box;}
    div.price-wrap div.calc-white ul {list-style-position:inside;margin-bottom:0;padding-bottom:1vw;background:var(--color-wht-80);border-radius:var(--main-border-radius);box-shadow:var(--lght-shadow);}
    div.price-wrap div.calc-white ul:nth-child(1) {width:35%;}
    div.price-wrap div.calc-white ul:nth-child(2) {width:60%;}
    div.price-wrap div.calc-white ul li b {min-width:8vw;}
    div.price-wrap p.note {width:100%;margin:3vw auto 2vw;text-align:center;font-size:var(--font-size-sl);color:var(--color-drk-80);}
    div.price-wrap p.note b {font-weight:500;color:var(--color-red);}

#form-order textarea {margin-bottom:2vw;min-height:20vw !important;}
#form-order textarea.store {min-height:16vw !important;}
#form-order textarea.store-sm {min-height:12vw !important;}
#form-order form button {margin:1vw auto 3vw 0;width:95%;min-width:200px;max-width:600px;}
#form-order form button svg.await {width:2.5vw;top:.3vw;right:2%;}
#form-order form button:hover svg.await {right:2%;}
#form-order input[type=range] {opacity:.5;position:absolute;bottom:-.5vw;left:0;right:0;}
#form-order p.total {margin-top:-1.5vw;font-size:var(--font-size-md);color:var(--color-grn-80);}
#form-order p.refetched {text-align:center;color:var(--color-red);}
#form-order div.store-flex {width:100%;margin:0 auto -3vw;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;}
#form-order div.store-flex ul {width:30%;list-style-position:inside;margin-bottom:3vw;}
#form-order div.store-flex ul li {opacity:.6;font-size:var(--font-size-sx);color:var(--color-drk-80);width:100%;display:flex;justify-content:space-between;align-items:flex-end;margin-bottom:1vw;overflow:hidden;}
#form-order div.store-flex ul:hover li {opacity:1;}
#form-order div.store-flex ul li b {color:var(--color-nav);font-size:var(--font-size-sy);text-shadow:none;font-weight:400;white-space:nowrap;margin:0 0 .3vw auto;}
#form-order div.store-flex ul li i {width:100%;height:1vw;border-bottom:dotted 1px var(--color-gry);margin:0 -20vw .2vw .2vw;overflow:hidden;}
#form-order div.store-flex ul li span {white-space:nowrap;margin-bottom:.1vw;color:var(--color-drk-60);}
#form-order div.store-flex ul li.client {text-transform:capitalize;font-size:var(--font-size-sy);font-weight:500;color:var(--color-grn);}
#form-order div.store-flex ul b.client {text-transform:capitalize;font-weight:500;}
#form-order div.store-flex ul.price {margin-top:5vw;}
#form-order div.store-flex fieldset.mt-2 {margin-top:7vw;}
#form-order div.store-flex select {max-width:28vw;}
#form-order div.order-range {width:80%;margin:2vw auto;transform:scale(1.2);}
#form-order div.order-range div.flex {width:100%;margin:0 auto .5vw;display:flex;justify-content:space-between;align-items:center;}
#form-order div.order-range div.flex div {font-size:var(--font-size-sx);font-weight:600;font-weight:600;text-align:center;color:var(--color-gry-60);}
#form-order div.order-range div.flex div span {font-size:1em;color:var(--color-gry-60);font-weight:200;}
#form-order div.order-range div.flex div.ready small {font-size:.8em;color:var(--color-drk-80);}
#form-order div.order-range div.flex div svg.arrows {width:3vw;fill:var(--color-gry-20);}
#form-order div.order-range div.flex div.ready {text-align:center;color:var(--color-grn-80);}
#form-order div.order-range div.flex div.ready span {font-size:1em;color:var(--color-red-80);}
#form-order div.order-range div.flex div.ready svg.arrows {width:3vw;fill:var(--color-grn-80);}
#form-order div.flex-end {flex-wrap:wrap;justify-content:center;}

#form-order.calculator {width:100%;max-width:1300px;margin:auto;}
#form-order.calculator form {justify-content:space-around;align-items:flex-start;}
#form-order.calculator fieldset.category-wrap {width:100%;max-width:1200px;margin:0vw auto 2vw;overflow:hidden;}
#form-order.calculator fieldset.category-wrap ul {flex-wrap:wrap;justify-content:center;}
#form-order.calculator fieldset.category-wrap ul li {width:9%;min-width:80px;font-size:.75em;margin:4px;padding:.4vw 0;}
#form-order.calculator fieldset.category-wrap ul li:hover {background:var(--color-yel-30);box-shadow:0px 0px 9px var(--color-gry-60);color:var(--color-nav);}
#form-order.calculator fieldset.category-wrap ul li.active {opacity:1;background:var(--color-grn-20);box-shadow:0px 0px 9px var(--color-gry-60);color:var(--color-red-80);}
#form-order.calculator fieldset.category-wrap ul li img {width:45%;margin-bottom:.5vw;}
#form-order.calculator fieldset.category-wrap div.track-ready {width:100%;padding:3vw 2vw 0;box-sizing:border-box;}
#form-order.calculator fieldset.category-wrap ul.disabled li:hover {opacity:.6;color:var(--color-drk-80);background:var(--color-blu-30);border:solid 1px var(--color-nav-60);}
#form-order.calculator fieldset.category-ready li {opacity:.6;}
#form-order.calculator fieldset.category-ready li:hover {opacity:1;box-shadow:none;}
#form-order.calculator fieldset.category-ready ul.disabled li:hover {opacity:.6;box-shadow:none;}
#form-order.calculator fieldset.category-ready ul.disabled li.active:hover {opacity:1;background:var(--color-grn-30);box-shadow:0px 0px 9px var(--color-gry-60);color:var(--color-red-80);}
#form-order.calculator fieldset.right {width:100%;max-width:1200px;}
#form-order.calculator fieldset.left fieldset.field-wrap {min-width:180px;max-width:250px;}
#form-order.calculator fieldset.left {width:100%;min-width:300px;max-width:1000px;flex-wrap:nowrap;}
#form-order.calculator div.flex {flex-wrap:wrap;justify-content:space-between;}
#form-order.calculator fieldset.short {width:35%;max-width:320px;}
#form-order.calculator fieldset.short div {width:42%;max-width:300px;margin-bottom:0vw;}
#form-order.calculator fieldset.long {width:63%;min-width:350px;max-width:700px;margin-right:0;}
#form-order.calculator fieldset.long div {width:100%;max-width:700px;margin-bottom:0vw;}
#form-order.calculator fieldset.long div.w-30 {width:33%;max-width:300px;}
#form-order.calculator fieldset.long div.w-70 {width:60%;max-width:500px;}
#form-order.calculator div.price-wrap {margin-top:-2vw;}
#form-order.calculator div.price-wrap div.calc-result {width:100%;margin:0 auto 2vw !important;display:flex;flex-wrap:wrap;justify-content:space-between;}
#form-order.calculator div.price-wrap div.calc-result ul {list-style-position:inside;margin-bottom:0;padding-bottom:1vw;background:var(--color-wht-80);border-radius:var(--main-border-radius);box-shadow:var(--lght-shadow);}
#form-order.calculator div.price-wrap div.calc-result ul:nth-child(1) {width:27%;}
#form-order.calculator div.price-wrap div.calc-result ul:nth-child(2) {width:35%;}
#form-order.calculator div.price-wrap div.calc-result ul:nth-child(3) {width:35%;}
#form-order.calculator div.price-wrap div.calc-result ul li {font-size:.9em;color:var(--color-drk-80);width:96%;display:flex;justify-content:space-between;align-items:flex-end;margin:0 auto .5vw;padding:0 .5vw;box-sizing:border-box;overflow:hidden;}
#form-order.calculator div.price-wrap div.calc-result ul li b {font-size:1em;min-width:38%;display:flex;justify-content:space-between;color:var(--color-nav);text-shadow:none;font-weight:400;white-space:nowrap;margin:0 0 .3vw auto;}
#form-order.calculator div.price-wrap div.calc-result ul li b.red {color:var(--color-red-80);}
#form-order.calculator div.price-wrap div.calc-result ul li b mark {padding:.2vw .4vw;background:var(--color-grn-20);color:var(--color-txt-80);font-size:.8em;}
#form-order.calculator div.price-wrap div.calc-result ul li b.price {color:var(--color-grn);}
#form-order.calculator div.price-wrap div.calc-result ul li b svg {width:.8vw;fill:var(--color-drk-80);}
#form-order.calculator div.price-wrap div.calc-result ul li b small {font-size:.8em;color:var(--color-drk-60);}
#form-order.calculator div.price-wrap div.calc-result ul li i {width:80%;height:1vw;border-bottom:dotted 1px var(--color-gry);margin:0 auto;overflow:hidden;}
#form-order.calculator div.price-wrap div.calc-result ul li span {white-space:nowrap;margin-bottom:.1vw;}
#form-order.calculator div.price-wrap div.calc-result ul li span mark {padding:.3vw .5vw;background:var(--color-blu);color:var(--color-wht-80);margin-left:.5vw;font-size:1em;}
#form-order.calculator div.price-wrap div.calc-result ul li span.caption {font-weight:300;color:var(--color-txt);}
#form-order.calculator div.price-wrap div.calc-result ul li.disabled {opacity:.3;}
#form-order.calculator div.price-wrap div.calc-result ul b.client {text-transform:capitalize;font-weight:500;}
#form-order.calculator div.price-wrap div.calc-result div.left {width:30%;}
#form-order.calculator div.price-wrap div.calc-result div.rght {width:62%;}
#form-order.calculator div.price-wrap div.calc-result h3 {margin-bottom:2vw;font-size:1em;color:var(--color-wht-80);border-radius:0;padding:1vw;margin-bottom:1vw;box-sizing:border-box;}
#form-order.calculator div.price-wrap div.calc-white ul {list-style-position:inside;margin-bottom:0;padding-bottom:1vw;background:var(--color-wht-80);border-radius:var(--main-border-radius);box-shadow:var(--lght-shadow);}
#form-order.calculator div.price-wrap div.calc-white ul:nth-child(1) {width:35%;}
#form-order.calculator div.price-wrap div.calc-white ul:nth-child(2) {width:60%;}
#form-order.calculator div.price-wrap div.calc-white ul li b {min-width:8vw;}
#form-order.calculator div.price-wrap p.note {display:none;}
#form-order.calculator p.calc-note {width:80%;text-align:center;margin:1vw auto;font-size:var(--font-size-sy);}
#form-order.calculator p.calc-note span {color:var(--color-slv);}



#form-user {width:90%;min-width:360px;max-width:1000px;margin:2vw auto;box-shadow:var(--block-shadow);background:var(--color-wht);border-radius:var(--main-border-radius);}
#form-user div.head {display:flex;justify-content:space-between;align-items:flex-end;height:3.5vw;width:100%;text-shadow:0px 0px 6px var(--color-drk);}
#form-user div.head h3 {width:95%;margin-bottom:.4vw;position:relative;font-size:var(--font-size-sm);text-shadow:0px 0px 1px var(--color-drk);transition:var(--transition-tip);}
#form-user div.head h3 span {float:none;display:inline-block;margin:0 .5vw;color:var(--color-yel-80);}
#form-user div.body {width:90%;margin:2vw auto 3vw;}
#form-user form {margin:2vw auto 0;padding:0;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;}
#form-user fieldset {width:45%;max-width:500px;margin:0 2vw 1vw 0;}
#form-user form button {margin:1vw 2vw 2vw auto;width:45%;min-width:300px;}
#form-user p.refetched {text-align:center;color:var(--color-red);}
#form-user ul {list-style-position:inside;padding-bottom:3vw;width:90%;margin:auto;}
#form-user ul h3 {margin-bottom:1vw;}
#form-user ul li {font-size:var(--font-size-sx);color:var(--color-drk-80);width:100%;padding:1vw .5vw 0;display:flex;justify-content:space-between;align-items:flex-end;overflow:hidden;cursor:pointer;}
#form-user ul li b {font-size:var(--font-size-sx);text-shadow:none;font-weight:400;white-space:nowrap;margin:0 0 .3vw auto;}
#form-user ul li i {width:100%;height:1vw;border-bottom:dotted 1px var(--color-gry);margin:0 -20vw .2vw .2vw;overflow:hidden;}
#form-user ul li span {white-space:nowrap;margin-bottom:.1vw;}
#form-user ul li date {color:var(--color-red);}
#form-user ul li:hover {background:var(--color-yel-40);}

#contact {display:flex;justify-content:center;align-items:flex-start;position:absolute;width:100%;height:100%;overflow-y:auto;}
#contact div.top-comp-wrap {width:60%;min-width:640px;max-width:1000px;}
#contact div.bg-wrap {background:var(--color-wht-80);margin:-1.6vw 0 0;border-radius:var(--main-border-radius);}
#contact form {display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;margin:4vw auto 1vw;}
#contact form div {width:48%;}
#contact form div:last-child {text-align:right;}
#contact fieldset {width:95%;margin:0 2vw 1vw 0;}
#contact fieldset label {text-align:left;}
#contact .fieldset-wrap {margin:0;min-width:300px;max-width:600px;}
#contact .fieldset-wrap .field-wrap {margin-bottom:2vw;}
#contact textarea {margin-bottom:0;min-height:24vw;}
#contact form button {margin:1vw 2.5vw .5vw auto;width:90%;}
#contact form button svg {width:2.5vw;top:.2vw;}

#register {width:100%;position:relative;}
#register div.top-comp-wrap {background:var(--color-wht-60);width:70%;min-width:900px;max-width:1200px;}
#register form {margin:1vw auto 2vw;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-end;}
#register form fieldset {width:30.5%;min-width:180px;max-width:400px;height:3.7vw;}
#register form fieldset.no-flex {margin:1vw 2vw !important;width:45vw;min-width:300px;max-width:500px;}
#register form fieldset.grn {min-width:240px;border-bottom:0;}
#register form fieldset.grn div {margin-top:3vw;display:flex;align-items:flex-end;justify-content:space-between;align-items:flex-end;}
#register form fieldset.grn legend  {min-height:2.5vw;}
#register form fieldset.check-ready {border-bottom:solid 1px var(--color-grn);background:var(--color-grn-20);}
#register form button {margin:2vw auto 2vw !important;min-width:270px;}
#register form button svg {width:2vw;top:.5vw;}
#register svg.attention {width:1.6vw;fill:var(--color-red);filter:none;top:.3vw;left:.5vw;}
#register legend {padding:.3vw 0 0 4vw;}
#register legend aside.tooltip {z-index:-1;opacity:0;padding:2vw;position:absolute;left:3vw;top:4.5vw;width:16vw;white-space:normal;transition:var(--transition-tip);}
#register legend aside.tooltip svg {left:0;top:-2.5vw;width:6vw;transform:rotate(-90deg);}
#register legend:hover aside {z-index:1;opacity:1;}
#register div.align-center {margin-top:-1vw;}
#register div.align-center fieldset {margin-left:1.5vw;}
#register div.long {width:60% !important;display:flex;flex-wrap:wrap;justify-content:space-between;}
#register div.long .radio-wrap {margin:2vw auto 0 0;}
#register div.long .radio-wrap div {width:100%;}
#register div.long .radio-wrap label {width:100%;height:3.5vw;}
#register p.errors {display:none;justify-content:space-around;font-weight:300;background:var(--color-red-240);font-size:var(--font-size-sm);width:100%;min-height:4vw;text-align:center;margin:2vw auto .5vw;}
#register p.errors span {color:var(--color-drk-60);}
#register p.errors span b {color:var(--color-grn-80);font-size:.9em;}
#register span.reset {margin:0 2vw 0 auto;font-size:var(--font-size-sl);}
#register div.button-box {width:32%;display:flex;flex-wrap:wrap;}

#fullfilment-form {width:100%;position:relative;}
#fullfilment-form div.top-comp-wrap {background:var(--color-wht-80);width:70%;min-width:1100px;max-width:1300px;}
#fullfilment-form form {margin:2vw auto 0;display:flex;flex-wrap:wrap;align-items:flex-start;justify-content:space-between;}
#fullfilment-form form.mt-0 {margin:0 auto 2vw;}
#fullfilment-form form label {text-align:left;}
#fullfilment-form form input:focus ~ label {color:var(--color-txt);top:0;}
#fullfilment-form form input[type=text]:focus ~ label,
#fullfilment-form form input[type=number]:focus ~ label{color:var(--color-red);top:-1.3vw;}
#fullfilment-form form fieldset.wrap-first aside.box-inline input[type=number]:focus ~ label{color:var(--color-red);top:0;}
#fullfilment-form form input[type=number] {font-size:var(--font-size-sx) !important;}
#fullfilment-form form input[type=file]:focus ~ label{color:var(--color-red);top:0;}
#fullfilment-form form fieldset {width:30.5%;min-width:180px;max-width:700px;height:3.7vw;transition:var(--transition-tip);}
#fullfilment-form form fieldset.field-wrap-2 {width:64%;min-width:360px;max-width:800px;}
#fullfilment-form form fieldset.field-wrap-2 svg {right:-1vw;}
#fullfilment-form form fieldset.fieldset-wrap {margin:0;height:auto;}
#fullfilment-form form fieldset.wrap-first {opacity:0;transform:scale(0);position:absolute;width:57%;min-width:380px;max-width:680px;}
#fullfilment-form form fieldset.wrap-last {opacity:0;transform:scale(0);position:absolute;width:40%;min-width:300px;max-width:500px;text-align:right;}
#fullfilment-form form fieldset.active {opacity:1;transform:scale(1);position:relative;}
#fullfilment-form form button {margin:2vw auto 1vw !important;width:100%;min-width:270px;max-width:500px;}
#fullfilment-form form button svg {width:2.5vw;top:.2vw;right:50%;}
#fullfilment-form form button:hover svg {right:2%;}
#fullfilment-form form fieldset.field-foto {width:63%;display:flex;overflow:hidden;overflow-x:auto;}
#fullfilment-form form fieldset.field-foto img {width:5vw;height:3.7vw;object-fit:cover;margin-left:2vw;box-shadow:0px 0px 9px var(--color-nav-60);border-radius:var(--field-border-radius);}
#fullfilment-form form fieldset.field-foto button {width:4vw;height:3.7vw;padding:0 !important;margin:0 2vw 0 0 !important;max-width:auto;min-width:auto;text-align:center;}
#fullfilment-form form fieldset.field-foto button svg {position:relative;right:auto;}
#fullfilment-form form fieldset.box-wrap {height:auto;width:100%;min-width:300px;max-width:800px;border-bottom:solid 1px var(--color-nav);border-radius:var(--field-border-radius);}
#fullfilment-form form fieldset.box-wrap label {position:relative;display:block;width:95%;min-width:300px;max-width:800px;}
#fullfilment-form form fieldset.box-wrap label b {font-size:var(--font-size-sy);font-weight:400;}
#fullfilment-form form fieldset.box-wrap label mark {position:absolute;right:50%;opacity:0;top:0;width:4vw;text-align:center;background:var(--color-grn-80);color:var(--color-wht);text-shadow:var(--text-shadow);padding:2px;transition:var(--transition-tip);}
#fullfilment-form form fieldset.box-wrap label mark.active {right:0;opacity:1;}
#fullfilment-form form fieldset.wrap-first aside.box-inline span {display:flex;position:relative;width:100%;margin-right:1vw;align-items:center;}
#fullfilment-form form fieldset.wrap-first aside.box-inline span label {width:90%;}
#fullfilment-form form fieldset.wrap-first aside.span-inline span {width:auto;min-width:100px;}
#fullfilment-form form fieldset.wrap-first aside.span-inline span:last-child label {color:var(--color-red);}
#fullfilment-form form fieldset.wrap-last aside.box-inline {justify-content:center;}
#fullfilment-form form fieldset.wrap-last aside.box-inline span {margin:1vw 1vw 0 0;text-align:left;}
#fullfilment-form form fieldset.wrap-last label {position:relative;width:90%;min-width:250px;max-width:500px;}
#fullfilment-form form fieldset.box-wrap aside {margin:1vw auto 0;width:95%;display:flex;align-items:flex-start;position:relative;}
#fullfilment-form form fieldset.box-wrap aside.flex-wrap {flex-wrap:wrap;}
#fullfilment-form form fieldset.box-wrap aside.flex-wrap span {width:95%;}
#fullfilment-form form fieldset.box-wrap aside.box-inline {flex-wrap:wrap;margin:0 auto 0 1vw;transition:var(--transition-tip);}
#fullfilment-form form fieldset.box-wrap aside.box-inline input {width:6.5vw;padding:1vw 2% .3vw;}
#fullfilment-form form fieldset.box-wrap aside.box-inline label {width:auto;min-width:80px;}
#fullfilment-form form fieldset.box-wrap aside.disabled {opacity:0.4;}
#fullfilment-form form fieldset.box-wrap aside p {font-size:var(--font-size-sy);margin-bottom:1vw;}
#fullfilment-form form fieldset.box-wrap aside b {color:var(--color-txt-80);}
#fullfilment-form form fieldset.box-wrap aside input[type=checkbox] {width:30px !important;}
#fullfilment-form form fieldset.box-wrap aside input[type=radio] {margin-bottom:-.3vw;}
#fullfilment-form form fieldset.box-wrap aside label {width:95%;position:relative;display:inline-block;}
#fullfilment-form form fieldset.box-wrap aside label.active {color:var(--color-grn);}
#fullfilment-form form fieldset.box-wrap aside.first {margin-top:1vw;}
#fullfilment-form form fieldset.box-wrap aside.last {margin-bottom:1vw;}
#fullfilment-form form fieldset.radio-wrap {width:100%;min-height:5vw;}
#fullfilment-form form fieldset.radio-wrap div.button-box-wrap {width:32%;margin-top:2vw;}
#fullfilment-form form fieldset.radio-wrap div.button-box-wrap table {width:100%;}
#fullfilment-form form fieldset.radio-wrap div.button-box-wrap table th {width:90%;text-align:left;}
#fullfilment-form form aside.total {font-size:var(--font-size-sl);color:var(--color-txt-80);margin-top:3vw;}
#fullfilment-form form aside.total span {font-family:var(--font-capt);font-size:1.3em;color:var(--color-grn);}
#fullfilment-form div.order-range {width:80%;margin:2vw auto;transform:scale(1.2);}
#fullfilment-form div.order-range div.flex {width:100%;margin:0 auto .5vw;display:flex;justify-content:space-between;align-items:center;}
#fullfilment-form div.order-range div.flex div {font-size:var(--font-size-sxy);font-weight:600;text-align:center;color:var(--color-gry-60);}
#fullfilment-form div.order-range div.flex div span {font-size:1em;color:var(--color-gry-60);font-weight:200;}
#fullfilment-form div.order-range div.flex div svg.arrows {width:3vw;fill:var(--color-gry-20);}
#fullfilment-form div.order-range div.flex div.ready {text-align:center;color:var(--color-grn-80);}
#fullfilment-form div.order-range div.flex div.ready span {font-size:1em;color:var(--color-red-80);}
#fullfilment-form div.order-range div.flex div.ready small {font-size:.8em;color:var(--color-drk-80);}
#fullfilment-form div.order-range div.flex div.ready svg.arrows {width:3vw;fill:var(--color-grn-80);}
#fullfilment-form form fieldset.wrap-first form {flex-wrap:nowrap;}
#fullfilment-form div.images {width:90%;margin:auto;align-items:center;justify-content:center;}
#fullfilment-form div.images img {width:30%;box-shadow:var(--blck-shadow);border-radius:var(--main-border-radius);transition:var(--transition-tip);cursor:pointer;}
#fullfilment-form div.images img:hover {z-index:2;transform:scale(2);}
#fullfilment-form div.store-flex {width:90%;margin:auto;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;}
#fullfilment-form div.store-flex ul {width:30%;list-style-position:inside;margin-bottom:3vw;}
#fullfilment-form div.store-flex ul li {font-size:var(--font-size-sx);color:var(--color-drk-80);width:100%;display:flex;justify-content:space-between;align-items:flex-end;margin-bottom:.5vw;overflow:hidden;}
#fullfilment-form div.store-flex ul li b {color:var(--color-nav);font-size:var(--font-size-sx);text-shadow:none;font-weight:400;white-space:nowrap;margin:0 0 .3vw auto;}
#fullfilment-form div.store-flex ul li i {width:100%;height:1vw;border-bottom:dotted 1px var(--color-gry);margin:0 -20vw .2vw .2vw;overflow:hidden;}
#fullfilment-form div.store-flex ul li span {white-space:nowrap;margin-bottom:.1vw;}
#fullfilment-form div.store-flex ul li.client {text-transform:capitalize;font-size:var(--font-size-sy);font-weight:500;color:var(--color-grn);}
#fullfilment-form div.store-flex ul b.client {text-transform:capitalize;font-weight:500;}
#fullfilment-form div.store-flex ul.price {margin-top:5vw;}


#fullfilment-price {width:100%;position:relative;}
#fullfilment-price div.top-comp-wrap {background:var(--color-wht-80);width:70%;min-width:900px;max-width:1200px;}
#fullfilment-price ul {margin:3vw auto 0;width:95%;color:var(--color-drk-80);}
#fullfilment-price ul li {display:inline-block;margin:0 0 3vw 1.5vw;text-decoration:underline;cursor:pointer;}
#fullfilment-price ul li.active {color:var(--color-red);}
#fullfilment-price form {opacity:.3;margin:0 auto 2vw;display:flex;flex-wrap:wrap;align-items:flex-start;justify-content:space-between;transition:var(--transition-tip);}
#fullfilment-price form.active {opacity:1;}
#fullfilment-price form h4 {width:100%;border-bottom:solid 1px var(--color-gry);}
#fullfilment-price form label {text-align:left;}
#fullfilment-price form input:focus ~ label{color:var(--color-txt);top:0;}
#fullfilment-price form input[type=text]:focus ~ label,
#fullfilment-price form input[type=number] {min-width:120px;}
#fullfilment-price form input[type=number]:focus ~ label{color:var(--color-red);top:-1.3vw;}
#fullfilment-price form input[type=file]:focus ~ label{color:var(--color-red);top:0;}
#fullfilment-price form fieldset {width:30.5%;min-width:180px;max-width:400px;height:3.7vw;}
#fullfilment-price form fieldset.fieldset-wrap {margin:0;height:auto;}
#fullfilment-price form button {margin:2vw auto 1vw !important;width:30%;min-width:240px;max-width:400px;}
#fullfilment-price form button svg {width:2.1vw;top:.4vw;right:2%;}
#fullfilment-price form fieldset.box-wrap {height:auto;width:100%;min-width:300px;max-width:1000px;}
#fullfilment-price form fieldset.box-wrap label b {font-size:var(--font-size-sl);font-weight:500;margin-bottom:1vw;display:block;}
#fullfilment-price form fieldset.box-wrap aside {margin:1vw auto 0;width:95%;display:flex;align-items:flex-start;position:relative;}
#fullfilment-price form fieldset.box-wrap aside.box-inline {flex-wrap:wrap;margin:0 auto 0 1vw;transition:var(--transition-tip);}
#fullfilment-price form fieldset.box-wrap aside.box-inline span {margin-right:1vw;text-align:left;}
#fullfilment-price form fieldset.box-wrap aside.box-inline input {width:6.5vw;padding:1vw 2% .3vw;}
#fullfilment-price form fieldset.box-wrap aside.disabled {opacity:0.4;}
#fullfilment-price form fieldset.box-wrap aside p {font-size:var(--font-size-sy);margin-bottom:1vw;}
#fullfilment-price form fieldset.box-wrap aside b {color:var(--color-txt-80);}
#fullfilment-price form fieldset.box-wrap aside input[type=checkbox] {width:30px !important;}
#fullfilment-price form fieldset.box-wrap aside label {width:80%;position:relative;}
#fullfilment-price form fieldset.box-wrap aside label.active {color:var(--color-grn);}
#fullfilment-price form fieldset.box-wrap aside.first {margin-top:3vw;}
#fullfilment-price form fieldset.box-wrap aside.last {margin-bottom:1vw;}
#fullfilment-price div.dtable {width:100%;font-family:var(--font-capt);font-size:var(--font-size-sx);border-radius:var(--main-border-radius);}
#fullfilment-price div.drow {cursor:pointer;}
#fullfilment-price div.dhead {background:var(--color-grn);text-align:center;white-space:nowrap;color:var(--color-wht-80);border-bottom:none;padding:1vw 1vw 1vw 1vw;font-size:var(--font-size-sl);padding-top:1vw;}
#fullfilment-price div.dhead:nth-child(1) {text-align:left;padding-left:5vw;}
#fullfilment-price div.dhead:nth-child(2) {width:20%;}
#fullfilment-price div.dhead:nth-child(3) {width:20%;}
#fullfilment-price div.dhead:nth-child(4) {width:20%;padding-right:5vw;}
#fullfilment-price div.dcell {text-align:center;align-content:center;color:var(--color-grn);white-space:nowrap;overflow:hidden;text-overflow:ellipsis;border-bottom:solid 1px var(--color-blu);padding:.5vw 1vw;}
#fullfilment-price div.dcell:nth-child(1) {text-align:left;padding-left:5vw;color:var(--color-drk);}
#fullfilment-price div.dcell:nth-child(4) {padding-right:5vw;}

div.store-flex ul h3 {margin-bottom:1vw;}

/*----------------------------- СРЕДНИЕ ЭКРАНЫ [ 1400, 1200 px ] -------------------------------------*/

@media (min-width:1600px){
    form p.info {margin-bottom:1vw;}
    button {min-width:200px;padding:.5vw 3.5vw .4vw .5vw !important;}
    button svg {right:.5vw;top:.3vw !important;width:12%;min-width:28px !important;max-width:30px !important;}
    button.grn {padding:.8vw 3.55vw .6vw .5vw;}
    button.grn svg {top:.6vw;width:1.4vw;}
    button.yel {padding:.8vw 3.5vw .6vw .5vw;}
    button.yel svg {fill:var(--color-grn-80);top:.6vw;width:1.5vw;}
    button.yel svg.sms {width:1.3vw !important;max-width:32px;}
    button.red {padding:.8vw 3.5vw .6vw .5vw;}
    button.red svg {top:.6vw;width:1.5vw;}
    button.sms {padding:10px;}
    button svg.plus {width:1.3vw;}
    button.more {text-align:center;padding:.5vw 2vw;}
    button.deli {padding:.5vw 3vw .5vw .8vw !important;max-width:16vw;}
    button.deli svg {right:.5vw;top:.3vw !important;width:12%;}
    button.deli:hover svg {right:.5vw;top:.3vw !important;width:12%;}
    button.full {padding:.5vw 3vw .5vw .8vw !important;max-width:16vw;}
    button.full svg {right:.5vw;top:.3vw !important;width:12%;}
    button.full:hover svg {right:.5vw;top:.3vw !important;width:12%;}
    button.filter {padding:.5vw 3vw .5vw .8vw !important;max-width:16vw;}
    button.filter svg {right:.5vw;top:5px !important;width:13%;}
    button.filter:hover svg {right:.5vw;top:5px !important;width:13%;}
    button.adrs {padding:.5vw 3vw .5vw 1.5vw;max-width:16vw;}
    button.adrs svg {width:1.8vw;top:.3vw;right:1.2vw;}
    button.adrs:hover {padding:.5vw 3vw .5vw 1.5vw !important;}
    button.adrs:hover svg {width:1.8vw;top:.3vw;}
    fieldset.field-wrap {margin-bottom:1vw !important;}

    label {position:absolute;left:.5vw;top:0;}
    legend {position:absolute;left:.5vw;top:-.2vw;padding:0;}
    legend svg {right:-3vw;top:-.5vw;width:1.5vw;}

    #form-order {margin:2vw auto 3vw;max-width:1800px;}
    #form-order div.mod-comp-wrap {width:90%;margin-top:1.5vw;}
    #form-order form {margin:2vw auto 0;}
    #form-order textarea {margin-bottom:1vw;}
    #form-order fieldset.mt-2 {margin-top:3vw !important;}
    #form-order fieldset {width:100%;max-width:510px;margin:0 2vw 1vw 0;}
    #form-order fieldset.category-wrap {margin:2vw auto;}
    #form-order fieldset.category-wrap ul li {width:12%;min-width:120px;padding:.6vw 0;font-size:var(--font-size-sxx);}
    #form-order fieldset.sm {margin:0 0 1vw;width:47%;}
    #form-order .fieldset-wrap {justify-content:flex-end;margin:0;width:47%;}
    #form-order .fieldset-wrap label {width:95%;}
    #form-order .fieldset-wrap input {width:100%;}
    #form-order fieldset.radio-wrap {padding-top:2vw;margin-bottom:2vw;}
    #form-order fieldset.long-wrap textarea {min-height:18vw;}
    #form-order fieldset legend {padding:.3vw 0 0 .5vw;}
    #form-order fieldset.short {width:28%;}
    #form-order fieldset.long {width:67%;margin-right:0;}
    #form-order div.order-range div.flex div {font-size:var(--font-size-sx);}
        div.price-wrap div.calc-result {margin:0 auto 2vw;}
        div.price-wrap div.calc-result ul li b {min-width:5vw;}
        div.price-wrap div.calc-result ul li b.red {color:var(--color-red-80);}
        div.price-wrap div.calc-result ul li b svg {width:16px;}
    #form-order fieldset.category-wrap ul {justify-content:center;}
    #form-order fieldset.category-wrap ul li {width:9.5%;min-width:90px;padding-bottom:.5vw;}
    #form-order fieldset.category-wrap ul li img {width:50%;margin-bottom:.6vw;}
    #form-order fieldset.category-wrap div.track-ready {width:100%;padding:3vw 2vw 0;box-sizing:border-box;}
    #form-order fieldset.category-wrap ul.disabled li:hover {opacity:.6;color:var(--color-drk-80);background:var(--color-blu-30);border:solid 1px var(--color-nav-60);}
    #form-order fieldset.category-ready li {opacity:.6;}
    #form-order fieldset.category-ready li:hover {opacity:1;box-shadow:none;}
    #form-order fieldset.category-ready ul.disabled li:hover {opacity:.6;box-shadow:none;}
    #form-order fieldset.category-ready ul.disabled li.active:hover {opacity:1;background:var(--color-grn-30);box-shadow:0px 0px 9px var(--color-gry-60);color:var(--color-red-80);}

    #form-user {width:90%;min-width:360px;margin:2vw auto;box-shadow:var(--block-shadow);background:var(--color-wht);border-radius:var(--main-border-radius);}
    #form-user div.head {display:flex;justify-content:space-between;align-items:flex-end;height:3.5vw;width:100%;text-shadow:0px 0px 6px var(--color-drk);}
    #form-user div.head h3 {width:95%;margin-bottom:.4vw;position:relative;font-size:var(--font-size-sm);text-shadow:0px 0px 1px var(--color-drk);transition:var(--transition-tip);}
    #form-user div.head h3 span {float:none;display:inline-block;margin:0 .5vw;color:var(--color-yel-80);}
    #form-user div.body {width:95%;margin:2vw auto 3vw;}
    #form-user form {margin:2vw auto 0;padding:0;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;}
    #form-user fieldset {width:45%;max-width:500px;margin:0 2vw 1vw 0;}
    #form-user form button {margin:1vw 2vw 2vw auto;width:45%;min-width:300px;}
    #form-user p.refetched {text-align:center;color:var(--color-red);}
    #form-user ul {list-style-position:inside;padding-bottom:3vw;width:90%;margin:auto;}
    #form-user ul h3 {margin-bottom:1vw;}
    #form-user ul li {font-size:var(--font-size-sx);color:var(--color-drk-80);width:100%;padding:1vw .5vw 0;display:flex;justify-content:space-between;align-items:flex-end;overflow:hidden;cursor:pointer;}
    #form-user ul li b {font-size:var(--font-size-sx);text-shadow:none;font-weight:400;white-space:nowrap;margin:0 0 .3vw auto;}
    #form-user ul li i {width:100%;height:1vw;border-bottom:dotted 1px var(--color-gry);margin:0 -20vw .2vw .2vw;overflow:hidden;}
    #form-user ul li span {white-space:nowrap;margin-bottom:.1vw;}
    #form-user ul li date {color:var(--color-red);}
    #form-user ul li:hover {background:var(--color-yel-40);}

    #contact div.top-comp-wrap {width:60%;min-width:640px;max-width:1100px;}
    #contact div.bg-wrap {margin:-1.6vw 0 0;}
    #contact form {display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;margin:4vw auto 1vw;}
    #contact form div {width:45%;}
    #contact form div:last-child {text-align:right;}
    #contact fieldset {width:90%;margin:0 2vw 1vw 0;}
    #contact fieldset label {text-align:left;}
    #contact .fieldset-wrap {margin:0;min-width:300px;max-width:600px;}
    #contact .fieldset-wrap .field-wrap {margin-bottom:2vw;width:100% !important;}
    #contact textarea {margin-bottom:0;min-height:20vw;}
    #contact form button {margin:1vw 2.5vw .5vw auto;width:100%;}
    #contact form button svg {width:2.5vw;top:.2vw;}


#register div.top-comp-wrap {width:70%;max-width:1200px;}
#register form {margin:.5vw auto;}
#register form fieldset {width:30.5%;min-width:180px;max-width:400px;height:3vw;}
#register form fieldset.no-flex {margin:1vw 2vw !important;width:45vw;min-width:300px;max-width:500px;}
#register form fieldset.bg-green {min-width:300px;}
#register form fieldset.bg-green div {margin-top:2vw;display:flex;align-items:flex-end;justify-content:space-between;align-items:flex-end;}
#register form fieldset.bg-green input {min-height:2.5vw;}
#register form fieldset.bg-green legend  {min-height:2.5vw;}
#register form fieldset.radio-wrap {height:3.8vw;}
#register form fieldset.check-ready {border-bottom:solid 1px var(--color-grn);background:var(--color-grn-20);}
#register form div.button-box-wrap table {width:100%;min-width:120px;}
#register form div.button-box-wrap table td {width:15%;}
#register form div.button-box-wrap table th {width:85%;text-align:left;}
#register form div.button-box-wrap table input[type=radio]{max-width:18px;}
#register form div.button-box-wrap {margin-bottom:0;width:50%;}
#register form div.button-box {margin:2vw 2vw 2vw auto;width:28%;display:flex;flex-wrap:wrap;}
#register form button {margin:2vw auto 2vw !important;min-width:320px;}
#register form button svg {width:1.8vw;top:.4vw;}
#register svg.attention {width:1.6vw;fill:var(--color-red);top:.3vw;left:.5vw;}
#register legend {padding:.3vw 0 0 4vw;}
#register legend aside.tooltip {z-index:-1;opacity:0;padding:2vw;position:absolute;left:3vw;top:4.5vw;width:16vw;white-space:normal;transition:var(--transition-tip);}
#register legend aside.tooltip svg {left:0;top:-2.5vw;width:6vw;transform:rotate(-90deg);}
#register legend:hover aside {z-index:1;opacity:1;}
#register div.align-center {margin-top:-1vw;}
#register div.align-center fieldset {margin-left:1.5vw;}
#register div.short {margin:2vw auto 2vw -1vw;width:32%;}
#register div.long {margin:2vw auto 2vw -1vw;width:65%;}
#register div.long .radio-wrap {width:100%;max-width:720px;max-height:50px;margin:2vw auto -1.5vw;}
#register div.long .radio-wrap:last-child {margin:0 auto .5vw;}
#register div.long .radio-wrap div {width:100%;}
#register div.long .radio-wrap label {width:100%;height:3.5vw;}
#register form div.flex {justify-content:space-around !important;}
}



@media (max-width:1100px){
    form {width:95%;}
    textarea {font-size:var(--font-size-sx);min-height:27.5vh;}
    select option {background:var(--color-brd);color:var(--color-wht-60);text-shadow:none;border:none;}
    select option:not(:checked) {background-color:var(--color-brd);}
    input.sm {width:96%;}
    button {font-size:var(--font-size-sl);font-weight:400;min-width:200px;max-width:300px;}
    button svg {right:.8vw;top:.3vw;width:2vw;}
    button.grn {width:25vw;padding:.8vw 3vw .6vw 1vw;}
    button.grn svg {top:.6vw;width:1.9vw;}
    button.yel {width:25vw;padding:.8vw 3vw .6vw 1vw;}
    button.yel svg {top:.6vw;width:1.9vw;}
    button svg.plus {width:1.8vw;}
    button.whatsapp {display:block;font-size:var(--font-size-sm);width:50px;height:53px;min-width:50px;max-width:60px;padding:.3vw 0 0;bottom:3vw;right:2vw;}
    button.whatsapp svg {position:relative;width:40px;top:auto;right:auto;}
    button.deli {padding:.6vw 3vw .6vw 1.5vw;}
    button.deli svg {width:1.8vw;top:.4vw;right:1.2vw;}
    button.deli:hover {padding:.5vw 3vw .5vw 1.5vw;}
    button.deli:hover svg {width:1.8vw;top:.4vw;}
    button.full {padding:.6vw 3vw .6vw 1.5vw;max-width:16vw;}
    button.full svg {width:1.8vw;top:.2vw;right:1.2vw;}
    button.full:hover {padding:.6vw 3vw .6vw 1.5vw !important;}
    button.full:hover svg {top:.2vw;}
    button.adrs {padding:.6vw 3vw .6vw 1.5vw;}
    button.adrs svg {width:1.8vw;top:.4vw;right:1.2vw;}
    button.adrs:hover {padding:.5vw 3vw .5vw 1.5vw;}
    button.adrs:hover svg {width:1.8vw;top:.4vw;}

    label {left:.5vw;top:0;font-size:var(--font-size-sm);}
    legend {left:.5vw;top:-.2vw;padding:0;font-size:var(--font-size-sy);}
    legend svg {right:-3vw;top:-.5vw;width:2vw;}
    input:not(.range),
    textarea, 
    select {font-size:var(--font-size-sl);margin-bottom:2vh;padding:1.8vw 2vw .5vw;}
    input:not(.range):focus,
    select:focus, 
    textarea:focus {padding:3vh 5vw 1vh 1vw;}
    input:not(.range):focus ~ label, 
    select:focus ~ label, 
    textarea:focus ~ label {color:var(--color-red);top:-2vh;}
    fieldset {position:relative;width:100%;min-width:240px;max-width:620px;margin:2vh auto 1.5vh;}
    fieldset textarea {font-size:var(--font-size-sm);padding:3vh 3%;}
    fieldset textarea:focus {background:var(--color-yel-40);border:0;}
    fieldset select {font-size:var(--font-size-sl);padding:3vh 5vw 1vh 1vw;}
    fieldset input:not(.range) {font-size:var(--font-size-sl);padding:3vh 5vw 1vh 1vw;}
    fieldset input:not(.range):focus {font-size:var(--font-size-sl);}
    fieldset input[type=checkbox] {max-width:14px;}
    fieldset input[type=range] {accent-color: var(--color-grn-40);}
    fieldset input[type=text] {padding-right:2vw;}
    fieldset input[name=fio] {text-transform:capitalize;}
    fieldset input[type=number] {padding:2vw 2vw 1vw !important;box-sizing:border-box;font-family:var(--font-crr);font-size:var(--font-size-sl) !important;font-weight:600;text-align:center;}
    fieldset input[type=number]:focus {padding:2vw 2vw 1vw;font-size:var(--font-size-ss);font-weight:600;text-align:center;}
    fieldset label span {float:right;}
    fieldset.radio-wrap label {font-size:var(--font-size-sl);}
    fieldset.radio-wrap div {width:48%;margin:1vh 1vw;}
    fieldset.radio-wrap div span {max-width:30px;margin-left:1vw;}
    fieldset.radio-wrap .fix-bottom {padding-top:4vh !important;}
    fieldset.no-flex {width:80%;max-width:650px;position:relative;display:block;margin:1vw auto;}
    fieldset.no-flex div {white-space:nowrap;border-bottom:0;display:flex;justify-content:space-between;}
    fieldset.no-flex input {margin-top:.4vw;width:15px;position:absolute;right:.5vw;}
    fieldset.ready label {color:var(--color-grn);top:0;width:95%;}
    fieldset.ready label svg.check {right:0;top:1.5vw;width:2vw;}
    fieldset.ready label svg.edit {right:0;top:1.5vw;width:2vw;fill:var(--color-grn-80);}
    fieldset.ready label svg.close {right:0;top:1.5vw;width:2vw;fill:var(--color-red-80);}
    fieldset.ready textarea {border-bottom:solid 1px var(--color-grn);background:var(--color-grn-20);}
    fieldset.ready input {border-bottom:solid 1px var(--color-grn);background:none;}
    fieldset.error label svg.close {display:block;position:absolute;right:0;top:1.4vw;width:1.8vw;fill:var(--color-red);}
    fieldset.error input {border-bottom:solid 1px var(--color-red);background:var(--color-red-20);}
    fieldset.bg-green {background:var(--color-grn-20);}
    fieldset input[type=range] {margin:none !important;border-bottom:none;border-top:none;padding:none !important;border-radius:none;}
    fieldset input[type=range]:focus {margin:none !important;border-bottom:none;border-top:none;padding:none !important;border-radius:none;}

    #form-auth {display:flex;justify-content:space-around;align-items:flex-start;position:absolute;width:100%;height:100%;overflow-y:auto;}
    #form-auth div.mod-comp-wrap {background:var(--color-wht-80);width:35%;min-width:400px;margin-top:5vw;}
    #form-auth form {width:100%;max-width:1400px;margin:3vw auto 1vw;} 
    #form-auth .flex {margin:2vw auto 1vw;width:90%;} 
    #form-auth fieldset {width:90%;}
    #form-auth div.flex {justify-content:space-around;}
    
    #form-order {width:100%;}
    #form-order div.mod-comp-wrap {width:95%;min-width:350px;max-width:1060px;margin-top:1.5vw;}
    #form-order form {margin:2vw auto 0;padding:0;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;}
    #form-order fieldset {width:46%;min-width:300px;margin:0 2vw 1vw 0;}
    #form-order div.flex {width:100%;margin:auto;display:flex;justify-content:space-between;align-items:flex-start;}
    #form-order fieldset.sm {margin-bottom:3vh;width:47%;min-width:160px;}
    #form-order fieldset.sm input {margin-bottom:1vw;width:100%;}
    #form-order fieldset.sm input[type=number] {padding:2vw 1vw 1vw;}
    #form-order fieldset.mb-2 {margin-bottom:2vw;}
    #form-order fieldset.mt-2 {margin-top:2vw;}
    #form-order .fieldset-wrap {margin:0;width:47%;min-width:300px;}
    #form-order .fieldset-wrap label {width:95%;}
    #form-order .fieldset-wrap input {width:100%;}
    #form-order .fieldset-wrap fieldset.field-wrap {width:100%;}
    #form-order .fieldset-wrap fieldset.sm {width:46%;}

    #form-order fieldset.radio-wrap {padding-top:2vw;margin-bottom:2vw;}
    #form-order fieldset.long-wrap {width:100%;min-width:300px;}
    #form-order fieldset legend {padding:.3vw 0 0 .5vw;}
    #form-order fieldset.short {width:28%;min-width:260px}
    #form-order fieldset.short div {width:42%;}
    #form-order fieldset.long {width:65%;margin-right:0;}
    #form-order fieldset.long div.w-30 mark,
    #form-order fieldset.long div.w-70 mark {font-size:.8em;margin-left:.3vw;}
    #form-order textarea {margin-bottom:2vw;}
    #form-order form button {min-width:220px;}
    #form-order form button svg.await {width:3vw;top:.3vw;}
    #form-order input[type=range] {opacity:.5;position:absolute;bottom:-.5vw;left:0;right:0;}
    #form-order p.total {margin:1vw 3vw 0 auto;}
    #form-order fieldset.category-wrap {width:95%;max-width:1000px;margin:3vw auto 2vw;}
        div.price-wrap {display:block;width:95%;max-width:1000px;padding:3vw 0;margin-top:-3vw;box-sizing:border-box;transition:all 1s ease 0s;}

    #form-user {width:90%;min-width:360px;max-width:1000px;margin:2vw auto;}
    #form-user div.head {display:flex;justify-content:space-between;align-items:flex-end;height:3.5vw;width:100%;}
    #form-user div.head h3 {width:95%;margin-bottom:0;font-size:var(--font-size-sl);}
    #form-user div.head h3 span {float:none;display:inline-block;margin:0 .5vw;}
    #form-user div.body {width:90%;margin:2vw auto 3vw;}
    #form-user form {margin:2vw auto 0;padding:0;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;}
    #form-user fieldset {width:45%;max-width:500px;margin:0 2vw 1vw 0;}
    #form-user form button {margin:1vw 2vw 2vw auto;min-width:260px;}
    #form-user p.refetched {text-align:center;color:var(--color-red);}
    #form-user ul {list-style-position:inside;padding-bottom:3vw;width:90%;margin:auto;}
    #form-user ul h3 {margin-bottom:1vw;}
    #form-user ul li {font-size:var(--font-size-sl);width:100%;padding:1vw .5vw 0;display:flex;justify-content:space-between;align-items:flex-end;overflow:hidden;}
    #form-user ul li b {font-size:var(--font-size-sl);text-shadow:none;font-weight:400;white-space:nowrap;margin:0 0 .3vw auto;}
    #form-user ul li i {width:100%;height:1vw;border-bottom:dotted 1px var(--color-gry);margin:0 -20vw .2vw .2vw;}
    #form-user ul li span {white-space:nowrap;margin-bottom:.1vw;}

    
    #contact div.top-comp-wrap {width:80%;min-width:600px;max-width:1000px;}
    #contact form {margin:6vh auto 1vh;}
    #contact form div {width:45%;}
    #contact fieldset {width:90%;margin:0 2vw 2vh 0;}
    #contact .fieldset-wrap {margin:0;width:48%;min-width:280px;max-width:410px;}
    #contact .fieldset-wrap .field-wrap {margin-bottom:2vw;}
    #contact form button {margin:2vh 10% 2vh 0;width:90%;}
    #contact form button svg {width:2.5vw;top:.2vw;}
    
    #register div.top-comp-wrap {width:90%;min-width:900px;max-width:1000px;}
    #register form {margin:4vh auto;}
    #register form fieldset {margin:0 0 4vh 0;width:30.5%;min-width:180px;max-width:300px;height:5vh;}
    #register form fieldset.no-flex {margin:1vw auto 0 0;width:45vw;min-width:300px;max-width:500px;}
    #register form fieldset.bg-green div {margin-top:3vw;display:flex;justify-content:space-between;}
    #register form fieldset.bg-green input {min-height:2.5vw;}
    #register form fieldset.bg-green legend  {min-height:2.5vh;}
    #register form fieldset.check-ready {border-bottom:solid 1px var(--color-grn);background:var(--color-grn-20);}
    #register form fieldset.radio-wrap {height:7vh;}
    #register form div.button-box-wrap table {width:100%;min-width:100px;}
    #register form div.button-box-wrap table td {width:15%;padding-top:2vh;}
    #register form div.button-box-wrap table th {width:85%;}
    #register form div.button-box-wrap table input[type=radio]{max-width:18px;}
    #register svg.attention {width:1.6vw;fill:var(--color-red);top:.3vw;left:.5vw;}
    #register legend {padding:.3vw 0 0 4vw;}
    #register legend aside.tooltip {z-index:-1;opacity:0;padding:2vw;position:absolute;left:3vw;top:4.5vw;width:16vw;white-space:normal;transition:var(--transition-tip);}
    #register legend aside.tooltip svg {left:0;top:-2.5vw;width:6vw;transform:rotate(-90deg);}
    #register legend:hover aside {z-index:1;opacity:1;}
    #register form button {margin:4vw 1vw 0 auto;min-width:280px;}
    #register form button svg {width:2vw;top:.5vw;}
    #register div.align-center {margin-top:-1vw;}
    #register div.align-center fieldset {margin-left:1.5vw;}
    #register div.long {width:55%;}
    #register div.long .radio-wrap {margin:2vw auto 0 0;}
    #register div.long .radio-wrap div {width:90%;}
    #register div.long .radio-wrap label {width:100%;height:3.5vw;}
    #register div.short {width:36%;text-align:right;}
    #register div.short .radio-wrap {margin:1vw 0 0 auto;}
}


/*----------------------------- МАЛЫЕ ЭКРАНЫ [ 800 px ] -------------------------------------*/

@media (max-width:800px){
    form {width:95%;}
    textarea {font-size:var(--font-size-sx);min-height:250px !important;}
    select option {background:var(--color-brd);color:var(--color-wht-60);text-shadow:none;border:none;}
    select option:not(:checked) {background-color:var(--color-brd);}
    input.sm {width:96%;}
    button {text-align:left;padding-left:4vw !important;font-size:var(--font-size-md);font-weight:400;min-width:200px;}
    button svg {width:15%;top:3px;right:10px;}
    button.grn {width:60vw !important;padding:2vw 5vw 2vw 0;}
    button.grn svg {width:32px !important;top:1vw !important;right:10px !important;fill:var(--color-wht-60) !important;}
    button.yel {width:60vw;padding:2vw 5vw 2vw 1vw;}
    button.yel svg {width:26px !important;top:2px;right:10px !important;}
    button.red {width:60vw;padding:2vw 5vw 2vw 0;}
    button.red svg {width:26px !important;top:2px;right:10px !important;fill:var(--color-wht-60) !important;}
    button svg.plus {width:5vw;}
    button.app-installer {z-index:1000;position:fixed;bottom:auto;top:20px;left:57vw;width:auto;min-width:100px;max-width:180px;font-size:.7em;padding:.5vw 2vw !important;}
    button.whatsapp {bottom:4vh;text-align:center;padding:3px !important;height:60px;max-width:60px;}
    button.whatsapp svg {position:relative;min-width:82%;top:1px;right:1px;left:0px;}
    button.deli {font-size:var(--font-size-mw);padding:1.5vw 3vw 1.5vw 1vw;width:35%;max-width:220px;}
    button.deli svg {width:2.4vw;top:.5vw;right:1.5vw;fill:var(--color-wht-80);}
    button.deli:hover {padding:1.5vw 3vw 1.5vw 1vw;text-shadow:0px 1px 5px var(--color-yel-80);}
    button.deli:hover svg {width:2.4vw;top:.5vw;right:1.5;fill:var(--color-wht);}
    button.full {font-size:var(--font-size-mw);padding:1.5vw 3vw 1.5vw 1vw;width:35%;max-width:220px;}
    button.full svg {width:2.4vw;top:.5vw;right:1.5;}
    button.full:hover {padding:1.5vw 3vw 1.5vw 1vw !important;}
    button.full:hover svg {top:.5vw;}
    button.adrs {font-size:var(--font-size-mw);padding:1.5vw 3vw 1.5vw 1vw;width:35%;max-width:220px;}
    button.adrs svg {width:2.4vw;top:.5vw;right:1.5vw;fill:var(--color-wht-80);}
    button.adrs:hover {padding:1.5vw 3vw 1.5vw 1vw;text-shadow:0px 1px 5px var(--color-yel-80);}
    button.adrs:hover svg {width:2.4vw;top:.5vw;right:1.5;fill:var(--color-wht);}
    button.filter {font-size:var(--font-size-mw);padding:1.5vw 3vw 1.5vw 1vw;width:35%;max-width:220px;}
    button.filter svg {width:2.4vw;top:.5vw;right:1.5;}   

    label {left:.5vw;top:0;font-size:var(--font-size-sm);}
    legend {left:.5vw;top:-10px;padding:0;font-size:var(--font-size-sy);}
    legend svg {right:-3vw;top:-.5vw;width:2vw;}
    input:not(.range),
    textarea, 
    select {font-size:var(--font-size-sl);margin-bottom:4vw;padding:3vw 5vw 1vw 1vw;}
    input:not(.range):focus,
    select:focus, 
    textarea:focus {padding:3vw 5vw 1vw 1vw;}
    input:not(.range):focus ~ label, 
    select:focus ~ label, 
    textarea:focus ~ label {color:var(--color-red);top:-6vw;}
    fieldset {position:relative;width:100%;min-width:320px;max-width:600px;margin:4vw auto 2vw !important;}
    fieldset textarea {background:var(--color-yel-10);font-size:var(--font-size-sm);padding:8vw 3vw 2vw;}
    fieldset textarea:focus {background:var(--color-yel-40);border:0;padding:8vw 3vw 2vw;}
    fieldset select {background:var(--color-yel-10);font-size:var(--font-size-sl);padding:8vw 3vw 2vw;}
    fieldset select:focus {padding:8vw 3vw 2vw;}
    fieldset input:not(.range) {background:var(--color-yel-10);font-size:var(--font-size-sl);padding:8vw 3vw 2vw;}
    fieldset input:not(.range):focus {padding:8vw 3vw 2vw;}
    fieldset input[type=checkbox] {max-width:14px;}
    fieldset input[type=range] {accent-color: var(--color-grn-40);}
    fieldset input[type=text] {padding-right:2vw;}
    fieldset input[name=fio] {text-transform:capitalize;}
    fieldset input[type=file] {position:absolute;padding:1.7vw 3% .3vw !important;}
    fieldset input[type=file]:focus {padding:1.7vw 3% .1vw;}
    fieldset input[type=number] {padding:7vw 2.5vw 3vw !important;font-size:var(--font-size-md) !important;}
    fieldset input[type=number]:focus {padding:7vw 2.5vw 3vw;}
    fieldset label span {float:right;}
    fieldset.radio-wrap label {font-size:var(--font-size-sl);}
    fieldset.radio-wrap div {width:50% !important;margin:1vw !important;}
    fieldset.radio-wrap div span {max-width:30px;margin-left:1vw;}
    fieldset.radio-wrap .fix-bottom {padding-top:6vw !important;}
    fieldset.no-flex {width:80%;max-width:650px;position:relative;display:block;margin:1vw auto;}
    fieldset.no-flex div {white-space:nowrap;border-bottom:0;display:flex;justify-content:space-between;}
    fieldset.no-flex input {margin-top:.4vw;width:15px;position:absolute;right:.5vw;}
    fieldset.ready label {color:var(--color-grn);top:0;width:95%;}
    fieldset.ready label svg.check {right:-2vw;top:6vw;width:6vw;}
    fieldset.ready label svg.edit {right:-2vw;top:6vw;width:6vw;}
    fieldset.ready label svg.close {right:-2vw;top:6vw;width:6vw;}
    fieldset.ready textarea {border-bottom:solid 1px var(--color-grn);background:var(--color-grn-20);}
    fieldset.ready input {border-bottom:solid 1px var(--color-grn);background:none;}
    fieldset.error label svg.close {display:block;position:absolute;right:0;top:1vw;width:4vw;fill:var(--color-red);}
    fieldset.error input {border-bottom:solid 1px var(--color-red);background:var(--color-red-20);}
    fieldset.bg-green {background:var(--color-grn-20);}
    fieldset input[type=range] {margin:none !important;border-bottom:none;border-top:none;padding:none !important;border-radius:none;}
    fieldset input[type=range]:focus {margin:none !important;border-bottom:none;border-top:none;padding:none !important;border-radius:none;}
    fieldset aside.error-field {right:0;left:0;}

    #form-auth {display:flex;justify-content:space-around;align-items:flex-start;position:absolute;width:100%;height:100%;overflow-y:auto;}
    #form-auth div.mod-comp-wrap {background:var(--color-wht-80);width:100%;min-width:340px;margin-top:0;}
    #form-auth form {width:80%;min-width:260px;max-width:380px;margin:8vw auto 6vw;} 
    #form-auth .flex {margin:2vw auto 1vw;width:90%;} 
    #form-auth fieldset {width:90%;min-width:280px;max-width:580px;}
    #form-auth div.flex {justify-content:space-around;}

    #form-order {width:100%;}
    #form-order div.mod-comp-wrap {width:100%;min-width:350px;max-width:900px;margin:0vw;}
    #form-order form {margin:2vw auto;padding:0;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;}
    #form-order fieldset {width:90%;min-width:320px;max-width:600px;}
    #form-order div.flex {width:90%;margin:auto;display:flex;flex-wrap:wrap;justify-content:center;align-items:flex-start;}
    #form-order div.reversed {width:100%;flex-direction:column-reverse;}
    #form-order fieldset.sm {width:90%;min-width:320px;max-width:600px;margin:7vw auto 3vw !important;}
    #form-order fieldset.sm input {margin-bottom:-3vw;width:100%;}
    #form-order fieldset.sm input[type=number] {padding:6vw 5vw 2vw;}
    #form-order fieldset.sm input.client {padding:6vw 5vw 2vw;}
    #form-order fieldset.sm svg {top:5vw;}
    #form-order fieldset.mb-2 {margin-bottom:2vw;}
    #form-order fieldset.mt-2 {width:100%;margin-top:10vw !important;}
    #form-order .fieldset-wrap {margin:0;width:90%;min-width:320px;max-width:600px;}
    #form-order .fieldset-wrap label {width:95%;}
    #form-order .fieldset-wrap input {width:100%;}
    #form-order fieldset.radio-wrap {padding:3vw 1vw 0;margin:3vw auto;}
    #form-order fieldset.radio-wrap label {width:90%;overflow:hidden;text-overflow:ellipsis;}
    #form-order fieldset.radio-wrap div {margin:3vw auto 0;}
    #form-order fieldset legend {padding:.3vw 0 0 .5vw;}
    #form-order fieldset.short {width:90%;min-width:320px;max-width:600px;}
    #form-order fieldset.short div {width:40%;min-width:140px;padding-top:3vw;}
    #form-order fieldset.long {flex-wrap:wrap;width:90%;min-width:320px;max-width:600px;margin-right:0;}
    #form-order fieldset.long div.w-30 {width:100%;padding-top:3vw;min-width:300px;max-width:600px;}
    #form-order fieldset.long div.w-70 {width:100%;min-width:300px;max-width:600px;}
    #form-order fieldset.w-50  {width:90%;}
    #form-order textarea {margin-bottom:0;min-height:240px !important;}
    #form-order textarea.store {min-height:50vw !important;}
    #form-order textarea.store-sm {min-height:50vw !important;}
    #form-order form button {margin:4vw auto 8vw;min-width:220px;}
    #form-order form button svg.await {width:9vw;top:0;right:2vw;}
    #form-order input[type=range] {opacity:.5;position:absolute;bottom:-.5vw;left:0;right:0;}
    #form-order p.total {margin-top:2vw;font-size:var(--font-size-lm);}
    #form-order p.refetched {font-size:var(--font-size-lm);margin:5vw auto -5vw;}
    #form-order div.store-flex {width:100%;}
    #form-order div.store-flex ul {width:100%;margin-bottom:3vw;}
    #form-order div.store-flex ul:last-child {margin-bottom:0;}
    #form-order div.store-flex ul li {font-size:var(--font-size-sx);width:100%;margin-bottom:2vw;}
    #form-order div.store-flex ul li b {font-size:var(--font-size-sm);margin:0 0 1vw auto;}
    #form-order div.store-flex ul li i {width:100%;height:1vw;border-bottom:dotted 1px var(--color-gry);margin:0 -50vw .2vw .2vw;overflow:hidden;}
    #form-order div.store-flex ul li span {white-space:nowrap;margin-bottom:.1vw;}
    #form-order div.store-flex ul li.client {text-transform:capitalize;font-size:var(--font-size-sy);font-weight:500;color:var(--color-grn);}
    #form-order div.store-flex ul b.client {text-transform:capitalize;font-weight:500;}
    #form-order div.store-flex ul.price {margin-top:5vw;}
    #form-order div.store-flex fieldset select {width:100% !important;min-width:280px;max-width:92vw;}
    #form-order div.flex-end {flex-wrap:wrap;justify-content:center;margin-top:5vw;}
    #form-order div.order-range {width:80%;margin:2vw auto;transform:scale(1.2);}
    #form-order div.order-range div.flex {width:100%;margin:0 auto .5vw;display:flex;flex-wrap:nowrap;justify-content:space-between;}
    #form-order div.order-range div.flex div {font-size:var(--font-size-sxy);font-weight:600;text-align:center;color:var(--color-gry-60);}
    #form-order div.order-range div.flex div span {display:none;}
    #form-order div.order-range div.flex div svg.arrows {margin-top:1vw;width:25px;}
    #form-order div.order-ready-info {width:100%;display:flex;flex-wrap:wrap;justify-content:center;}
    #form-order div.order-ready-info h3 {width:90%;margin:0 auto 2vw;}
    #form-order div.order-ready-info ul {width:90%;margin-bottom:3vw;}
    #form-order div.order-ready-info ul li {font-size:var(--font-size-sm);margin-bottom:3vw;}
    #form-order div.order-ready-info ul li span {white-space:nowrap;width:auto;}
    #form-order div.order-ready-info ul li b {font-size:var(--font-size-sm);margin-bottom:1vw;}
    #form-order div.order-ready-info ul li i {width:100%;height:1vw;margin:0 -50vw .2vw .2vw;}
    #form-order fieldset.category-wrap {width:100%;margin:8vw auto 3vw !important;}
    #form-order fieldset.category-wrap ul {display:flex;flex-wrap:wrap;justify-content:center;}
    #form-order fieldset.category-wrap ul li {font-size:var(--font-size-mw);width:28%;min-width:90px;max-width:200px;margin:0 1vw 2vw;padding:2vw 0 1vw !important;}
    #form-order fieldset.toggle-wrap {display:flex;flex-wrap:wrap;justify-content:center;max-width:600px;}
        div.price-wrap {margin:0 auto;width:100%;min-width:320px;max-width:800px;padding:0;}
        div.price-wrap div.dtable {display:table;width:100%;}
        div.price-wrap div.drow {display:table-row;}
        div.price-wrap div.drow:last-child div.dcell {padding-bottom:1vw;}
        div.price-wrap div.dhrow {display:table-row;}
        div.price-wrap div.dhead {display:table-cell;font-weight:500;font-size:var(--font-size-mx);padding-top:1vw;}
        div.price-wrap div.dhead:nth-child(4) {width:12px;padding-right:2vw;}
        div.price-wrap div.dcell {display:table-cell;font-size:var(--font-size-mx);}
        div.price-wrap div.dcell:nth-child(1) {padding-left:1vw;}
        div.price-wrap div.dcell:nth-child(1) svg {width:18px;margin-right:1vw;}
        div.price-wrap div.dcell:nth-child(4) {padding-right:1vw;}
        div.price-wrap div.dcell mark {padding:.2vw .4vw;font-size:.9em;}
        div.price-wrap div.dactv div {font-weight:600;}
        div.price-wrap div.dactv mark {padding:.2vw .4vw;font-size:.9em;font-weight:400;}
        div.price-wrap div.calc-result {width:100%;min-width:340px;max-width:800px;margin:0 auto 2vw;flex-wrap:wrap;justify-content:center;}
        div.price-wrap div.calc-result ul {width:100% !important;max-width:800px;margin:0 auto 8vw !important;}
        div.price-wrap div.calc-result ul li {font-size:1.1em !important;margin:2.5vw auto;padding:0 1vw;width:90%;max-width:350px;}
        div.price-wrap div.calc-result ul li b {font-size:1.2em !important;min-width:35vw;font-size:var(--font-size-sm);background:var(--color-wht);margin-bottom:-1vw;padding-bottom:2vw;}
        div.price-wrap div.calc-result ul li b.red {color:var(--color-red-80);}
        div.price-wrap div.calc-result ul li b mark {padding:.2vw .4vw;background:var(--color-grn-20);color:var(--color-txt-80);font-size:.9em;}
        div.price-wrap div.calc-result ul li b.price {min-width:35vw;color:var(--color-grn);}
        div.price-wrap div.calc-result ul li b svg {width:14px !important;fill:var(--color-drk-80);}
        div.price-wrap div.calc-result ul li i {display:none;width:10%;height:1vw;border-bottom:dotted 1px var(--color-gry);margin:0 -10vw .2vw .2vw;overflow:hidden;}
        div.price-wrap div.calc-result ul li span {white-space:nowrap;margin-bottom:.1vw;}
        div.price-wrap div.calc-result ul li span mark {padding:0;background:var(--color-grn-30);color:var(--color-red);font-size:1em;}
        div.price-wrap div.calc-result ul li span.caption {font-weight:300;color:var(--color-txt);}
        div.price-wrap div.calc-result ul li.disabled {opacity:.3;}
        div.price-wrap div.calc-result ul b.client {text-transform:capitalize;font-weight:500;}
        div.price-wrap div.calc-result div.left {width:30%;}
        div.price-wrap div.calc-result div.rght {width:62%;}
        div.price-wrap div.calc-result h3 {margin-bottom:2vw;font-size:var(--font-size-sl);padding:3vw 2vw 2vw;}
        div.price-wrap p.note {width:90%;margin:4vw auto 3vw;color:var(--color-drk-90);}
        div.price-wrap p.note:last-child {margin-bottom:9vw;}
    #form-order.calculator fieldset.category-wrap ul li {width:20%;min-width:110px;font-size:var(--font-size-sx);margin:.6vw;padding:.2vw 0;}
    #form-order.calculator fieldset.left {flex-wrap:wrap;}    
    #form-order.calculator fieldset.left fieldset.field-wrap {min-width:100px;max-width:140px;}
    #form-order.calculator fieldset.radio-wrap {background:none !important;}
    #form-order.calculator fieldset.long,
    #form-order.calculator fieldset.short {width:90%;max-width:600px;}
    #form-order.calculator fieldset.short div {width:40% !important;max-width:260px;}
    #form-order.calculator fieldset.radio-wrap div.w-30 {width:100% !important;max-width:600px;}
    #form-order.calculator fieldset.radio-wrap div.w-70 {width:100% !important;max-width:600px;}

    #form-user {width:100%;min-width:360px;max-width:1000px;margin:0 auto 2vw;}
    #form-user div.head {display:flex;justify-content:space-between;align-items:flex-end;height:8vw;width:100%;}
    #form-user div.head h3 {width:100%;overflow:hidden;text-overflow:ellipsis;margin-bottom:0;font-size:var(--font-size-sl);}
    #form-user div.head h3 span {float:none;display:inline-block;margin:0 .5vw;}
    #form-user div.head h3 b {display:none;}
    #form-user div.body {width:90%;margin:5vw auto 8vw;}
    #form-user form {margin:8vw auto 6vw;padding:0;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;}
    #form-user fieldset {width:45%;max-width:500px;margin:0 2vw 1vw 0;}
    #form-user form button {margin:2vw auto 3vw;width:90%;min-width:260px;}
    #form-user p.refetched {text-align:center;color:var(--color-red);}
    #form-user ul {display:none;}

    #contact div.top-comp-wrap {width:100%;min-width:340px;max-width:600px;}
    #contact form {margin:8vw auto 6vw;}
    #contact form div {width:100%;}
    #contact fieldset {width:90%;margin:0 auto 4vw;min-width:260px;max-width:500px;}
    #contact .fieldset-wrap {margin:auto;width:100%;min-width:280px;max-width:410px;display:flex;flex-wrap:wrap;justify-content:center;}
    #contact .fieldset-wrap .field-wrap {margin-bottom:2vw;}
    #contact form button {margin:3vw auto 0;min-width:240px;max-width:320px;}
    #contact form button svg {width:8vw;top:.8vw;right:1vw;}
    
    #register div.top-comp-wrap {width:100%;min-width:340px;max-width:800px;margin:0;}
    #register form {width:90%;margin:8vw auto 6vw;}
    #register form fieldset {margin:0 auto 15vw !important;width:90%;min-width:300px;max-width:500px;height:8vw;}
    #register form fieldset.no-flex {margin:1vw auto 0 0;width:100%;min-width:300px;max-width:500px;}
    #register form fieldset.bg-green {min-height:16vw !important;width:90%;min-width:300px;}
    #register form fieldset.bg-green div {margin-top:4vw;display:flex;justify-content:space-between;}
    #register form fieldset.bg-green input {min-height:2.5vw;}
    #register form fieldset.bg-green legend  {min-height:2.5vw;margin-left:6vw;}
    #register form fieldset.check-ready {border-bottom:solid 1px var(--color-grn);background:var(--color-grn-20);}
    #register form fieldset.radio-wrap {height:12vw;padding-top:15px;}
    #register form div.button-box-wrap table {width:100%;min-width:100px;}
    #register form div.button-box-wrap table td {width:15%;}
    #register form div.button-box-wrap table th {width:85%;}
    #register form div.button-box-wrap table input[type=radio]{max-width:18px;}
    #register svg.attention {width:4vw;fill:var(--color-red);top:.3vw;left:-4vw;}
    #register legend {padding:.3vw 0 0 4vw;}
    #register legend aside.tooltip {z-index:-1;opacity:0;padding:2vw;position:absolute;left:3vw;top:4.5vw;width:60vw;white-space:normal;font-size:var(--font-size-md);text-align:center;}
    #register legend aside.tooltip svg {left:0;top:-2.5vw;width:6vw;transform:rotate(-90deg);}
    #register legend:hover aside {z-index:1;opacity:1;}
    #register form button {margin:8vw auto !important;min-width:240px;max-width:400px;}
    #register form button svg {width:7vw;top:1vw;right:2vw;}
    #register div.align-center {margin-top:-1vw;}
    #register div.align-center fieldset {margin-left:1.5vw;}
    #register div.long {width:90% !important;min-width:300px;max-width:600px;flex-wrap:wrap;margin:0 auto !important;}
    #register div.long .radio-wrap {margin:4vw auto 0 !important;}
    #register div.long .radio-wrap div {width:90%;height:90%;}
    #register div.long .radio-wrap label {width:100%;height:3.5vw;height:100%;}
    #register p.errors {width:90%;margin:6vw auto 0;display:flex;justify-content:flex-start;flex-wrap:wrap;}
    #register p.errors span {margin-top:2vw;text-align:left;}
    #register span.reset {text-align:center;width:100%;}
    #register div.flex div.button-box {margin-top:30px;justify-content:center;}
    

    #fullfilment-form div.top-comp-wrap {margin-top:0;width:100%;min-width:340px;max-width:800px;background:var(--color-wht);}
    #fullfilment-form form {margin:0 auto;}
    #fullfilment-form form.mt-0 {margin:6vw auto 2vw;}
    #fullfilment-form form label {text-align:left;}
    #fullfilment-form form input:focus ~ label {color:var(--color-txt);top:0;}
    #fullfilment-form form input[type=text]:focus ~ label,
    #fullfilment-form form input[type=number]:focus ~ label{color:var(--color-red);top:-5vw;}
    #fullfilment-form form fieldset.wrap-first aside.box-inline input[type=number]:focus ~ label{color:var(--color-red);top:0;}
    #fullfilment-form form fieldset {width:90%;min-width:300px;max-width:600px;height:3.7vw;}
    #fullfilment-form form fieldset.field-wrap-2 {width:90%;min-width:320px;max-width:800px;}
    #fullfilment-form form fieldset.field-wrap-2 svg {right:-1vw;}
    #fullfilment-form form fieldset.field-wrap {margin:0 auto 4vw !important;height:auto;}
    #fullfilment-form form fieldset.fieldset-wrap {margin:0;height:auto;}
    #fullfilment-form form fieldset.wrap-first {opacity:0;transform:scale(0);position:absolute;width:100%;min-width:320px;max-width:600px;}
    #fullfilment-form form fieldset.wrap-last {opacity:0;transform:scale(0);position:absolute;width:100%;min-width:320px;max-width:600px;text-align:left;}
    #fullfilment-form form fieldset.active {opacity:1;transform:scale(1);position:relative;}
    #fullfilment-form form button {margin:4vw auto 1vw !important;width:100%;min-width:300px;max-width:500px;}
    #fullfilment-form form button svg {width:8vw;max-width:auto;top:.8vw;right:2%;}
    #fullfilment-form form fieldset.field-foto {width:100%;display:flex;flex-wrap:wrap;overflow:hidden;}
    #fullfilment-form form fieldset.field-foto img {width:22vw;height:15vw;margin-left:2vw;}
    #fullfilment-form form fieldset.box-wrap {height:auto;width:100%;min-width:300px;max-width:600px;border-bottom:solid 1px var(--color-nav);border-radius:var(--field-border-radius);}
    #fullfilment-form form fieldset.box-wrap label {font-size:var(--font-size-sl);width:90%;min-width:300px;max-width:600px;}
    #fullfilment-form form fieldset.box-wrap label b {font-size:var(--font-size-sm);}
    #fullfilment-form form fieldset.box-wrap label mark {position:absolute;right:50%;opacity:0;top:0;width:auto;min-width:6vw;text-align:center;background:var(--color-grn-80);color:var(--color-wht);text-shadow:var(--text-shadow);padding:2px;transition:var(--transition-tip);}
    #fullfilment-form form fieldset.box-wrap label mark.active {right:0;opacity:1;}
    #fullfilment-form form fieldset.wrap-first aside.box-inline span {display:flex;position:relative;width:100%;margin-right:1vw;align-items:flex-start;}
    #fullfilment-form form fieldset.wrap-first aside.box-inline span label {width:90%;}
    #fullfilment-form form fieldset.wrap-first aside.span-inline span {width:auto;min-width:100px;margin-bottom:3vw;}
    #fullfilment-form form fieldset.wrap-first aside.span-inline span:last-child label {color:var(--color-red);}
    #fullfilment-form form fieldset.wrap-last aside.box-inline {width:100%;justify-content:center;}
    #fullfilment-form form fieldset.wrap-last aside.box-inline span {margin:1vw 1vw 0 0;text-align:left;}
    #fullfilment-form form fieldset.wrap-last label {position:relative;width:90%;min-width:300px;max-width:600px;}
    #fullfilment-form form fieldset.box-wrap aside {margin:1vw auto 0;width:95%;display:flex;align-items:flex-start;position:relative;}
    #fullfilment-form form fieldset.box-wrap aside.flex-wrap span {width:95%;display:flex;flex-wrap:nowrap;}
    #fullfilment-form form fieldset.box-wrap aside.box-inline {flex-wrap:wrap;margin:0 auto 0 1vw;transition:var(--transition-tip);}
    #fullfilment-form form fieldset.box-wrap aside.box-inline input {width:21vw;padding:2vw 2%;margin:2vw 1vw 3vw;}
    #fullfilment-form form fieldset.box-wrap aside.box-inline label {width:auto;min-width:80px;}
    #fullfilment-form form fieldset.box-wrap aside.disabled {opacity:0.4;}
    #fullfilment-form form fieldset.box-wrap aside p {font-size:var(--font-size-sy);margin-bottom:1vw;}
    #fullfilment-form form fieldset.box-wrap aside b {color:var(--color-txt-80);}
    #fullfilment-form form fieldset.box-wrap aside input[type=checkbox] {width:30px !important;}
    #fullfilment-form form fieldset.box-wrap aside input[type=radio] {margin-bottom:-.3vw;}
    #fullfilment-form form fieldset.box-wrap aside label {width:85%;position:relative;display:inline-block;}
    #fullfilment-form form fieldset.box-wrap aside label.active {color:var(--color-grn);}
    #fullfilment-form form fieldset.box-wrap aside.first {margin-top:5vw;}
    #fullfilment-form form fieldset.box-wrap aside.last {margin-bottom:4vw;}
    #fullfilment-form form fieldset.radio-wrap {width:100%;align-items:center;min-height:15vw;padding-top:4vw;}
    #fullfilment-form form aside.total {text-align:center;font-size:var(--font-size-md);margin-top:12vw;}
    #fullfilment-form form aside.total span {font-family:var(--font-capt);font-size:1.3em;color:var(--color-grn);}
    #fullfilment-form form form {flex-wrap:wrap;}
    #fullfilment-form form form fieldset.field-wrap {margin-bottom:15vw;}
    #fullfilment-form div.store-flex {width:90%;}
    #fullfilment-form div.store-flex ul {width:100%;margin-bottom:3vh;}
    #fullfilment-form div.store-flex ul:last-child {margin-bottom:0;}
    #fullfilment-form div.store-flex ul li {font-size:var(--font-size-sx);width:100%;margin-bottom:1vh;}
    #fullfilment-form div.store-flex ul li b {font-size:var(--font-size-sx);margin:0 0 .4vw auto;}
    #fullfilment-form div.store-flex ul li i {width:100%;height:1vw;border-bottom:dotted 1px var(--color-gry);margin:0 -50vw .2vw .2vw;overflow:hidden;}
    #fullfilment-form div.store-flex ul li span {white-space:nowrap;margin-bottom:.1vw;}
    #fullfilment-form div.store-flex ul li.client {text-transform:capitalize;font-size:var(--font-size-sy);font-weight:500;color:var(--color-grn);}
    #fullfilment-form div.store-flex ul b.client {text-transform:capitalize;font-weight:500;}
    #fullfilment-form div.store-flex ul.price {margin-top:5vw;}
    #fullfilment-form div.flex-end {flex-wrap:wrap;justify-content:center;margin-top:4vh;}
    #fullfilment-form div.order-range {width:80%;margin:2vw auto;transform:scale(1.2);}
    #fullfilment-form div.order-range div.flex {width:100%;margin:0 auto .5vw;display:flex;flex-wrap:nowrap;justify-content:space-between;}
    #fullfilment-form div.order-range div.flex div {font-size:var(--font-size-sxy);font-weight:600;text-align:center;color:var(--color-gry-60);}
    #fullfilment-form div.order-range div.flex div span {display:none;}
    #fullfilment-form div.order-range div.flex div svg.arrows {display:none;margin-top:1vw;width:25px;}
    #fullfilment-form form fieldset.wrap-first form {flex-wrap:wrap;}
    #fullfilment-form div.images {align-items:center;}
    #fullfilment-form div.images img {width:30%;box-shadow:var(--blck-shadow);border-radius:var(--main-border-radius);transition:var(--transition-tip);cursor:pointer;}
    #fullfilment-form div.images img:hover {z-index:2;transform:scale(2);}
    #fullfilment-form form fieldset.bg-green legend  {min-height:2.5vw;margin-left:2vw;}
    #fullfilment-form form fieldset.radio-wrap {height:12vw;}
    #fullfilment-form form div.button-box-wrap table {width:100%;min-width:100px;}
    #fullfilment-form form div.button-box-wrap table td {padding-top:4vw;}
    #fullfilment-form form div.button-box-wrap table input[type=radio]{max-width:18px;}
}
