div.head {position:relative;width:100%;height:5vh;background:var(--color-nav);text-shadow:0px 0px 6px var(--color-drk);border-radius:var(--main-border-radius) var(--main-border-radius) 0 0;}
div.head h3 {position:absolute;bottom:1vh;left:2vw;right:2vw;color:var(--color-wht-80);text-shadow:var(--blck-shadow);white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
div.head h3 span {color:var(--color-yel);float:right;text-transform:none;}
div.head h3 b {text-transform:capitalize;}
div.head div.dtable {margin:2vh auto .3vh;}
div.head .order-delete {position:relative;cursor:pointer;}
div.head .order-delete svg {position:absolute;bottom:0;left:-2.5vw;width:1.5vw;fill:var(--color-red);}
div.head .order-delete b {position:absolute;top:-8vh;left:-20vw;white-space:nowrap;opacity:0;transition:var(--transition-tip);}
div.head .order-delete b.active {top:0;left:-20vw;opacity:1;}
div.head .order-delete b:hover {color:var(--color-red);}
div.body {position:relative;margin:auto;}
div.body div.dtable {margin:2vh auto 6vh;}

#content {overflow:hidden;overflow-y:auto;position:fixed;padding-bottom:5vw;left:0;right:0;top:11.6vw;bottom:4vh;z-index:100;overflow-x:hidden;}
#content article.main {opacity:0;transition:var(--transition-tip);}
#content article.active {opacity:1;}

#home {width:90%;min-width:1080px;max-width:1300px;margin:auto;}
#home ul {margin:0 0 0 auto;display:flex;flex-wrap:wrap;justify-content:center;}
#home ul li {background:var(--color-nav);color:var(--color-wht-40);margin:0 2vw 2vw 0;padding:1.5vw 1vw .5vw;width:42%;max-width:220px;min-width:160px;border-radius:var(--main-border-radius);list-style-type:none;text-align:center;text-transform:uppercase;box-shadow:var(--dark-but-shadow);cursor:pointer;}
#home ul li svg {fill:var(--color-wht-40);width:40%;min-width:60px;max-width:100px;margin:1vw auto;}
#home ul li:nth-child(2) svg {width:30%;min-width:50px;max-width:70px;margin-top:20px;}
#home ul li span {font-size:var(--font-size-sm);display:block;white-space:nowrap;}
#home ul li:hover {background:var(--color-nav-80);text-shadow:var(--blck-shadow);color:var(--color-wht-80);box-shadow:var(--block-shadow-a);}
#home ul li:hover svg {fill:var(--color-yel-60);filter: drop-shadow( 0px 0px 6px var(--color-wht));}
#home div.dtable {margin-top:4vw;}
#home div.dcell {width:50%;vertical-align:top;}
#home button.register {display:none;margin:2vw 30% 1vw 30%;}

#form-home {margin:0 auto;width:90%;min-width:360px;max-width:600px;border-radius:var(--main-border-radius);box-shadow:var(--block-shadow);padding-bottom:2vw;}
#form-home form {width:80%;margin:2.5vw auto 1vw;}
#form-home form button {width:12vw;}
#form-home form button svg {right:1vw;top:.5vw;width:2vw;}
#form-home form button:hover svg {top:.5vw;right:.5vw;}
#form-home div.flex {justify-content:space-around;margin-top:3vw;}
#form-home div.flex a {white-space:nowrap;}
#form-home p.align-center {margin-top:20px;}
#form-home p.reset-info {text-align:center;margin:0 auto 20px;}

#delivery {text-align:center;width:90%;max-width:1300px;margin:1vw auto;background:var(--color-wht);border-radius:var(--main-border-radius);box-shadow:var(--block-shadow);}
#delivery svg {fill:var(--color-gry);margin:.5vw auto -.1vw;width:2vw;max-width:30px;}
#delivery svg.warehouse-out {opacity:.7;width:1.8vw;}
#delivery svg.await {fill:var(--color-txt);width:2.1vw;}
#delivery svg.almaty {width:2.4vw;max-width:34px;}
#delivery svg.total {fill:var(--color-txt);width:2vw;}
#delivery svg.check {fill:var(--color-grn);width:1.8vw;}
#delivery svg.close {fill:var(--color-red);width:1.8vw;}
#delivery svg.info {position:absolute;right:2vw;top:1.1vw;fill:var(--color-blu);width:1.6vw;}
#delivery div.dtable {background:var(--color-wht);font-family:var(--font-capt);font-size:var(--font-size-sxx);border-radius:var(--main-border-radius);overflow:hidden;}
#delivery div.drow {cursor:pointer;}
#delivery div.dhead {background:var(--color-grn);text-align:center;white-space:nowrap;color:var(--color-wht-80);border-bottom:none;padding:1vw 1vw .5vw 1vw;font-size:var(--font-size-sx);padding-top:.6vw;}
#delivery div.dhead:nth-child(1) {width:10%;border-radius:var(--main-border-radius) 0 0 0;}
#delivery div.dhead:nth-child(2) {width:5%;text-align:left;}
#delivery div.dhead:nth-child(3) {width:12%;text-align:left;}
#delivery div.dhead:nth-child(4) {width:25%;text-align:left;}
#delivery div.dhead:nth-child(5) {width:15%;text-align:left;}
#delivery div.dhead:nth-child(6) {width:5%;text-align:left;}
#delivery div.dhead:nth-child(7) {width:5%;}
#delivery div.dhead:nth-child(8) {width:5%;}
#delivery div.dhead:nth-child(9) {width:5%;}
#delivery div.dcell {text-align:center;align-content:center;color:var(--color-gry);white-space:nowrap;overflow:hidden;text-overflow:ellipsis;border-bottom:solid 1px var(--color-blu);padding:.5vw 1vw;}
#delivery div.dcell:nth-child(1) {color:var(--color-drk);font-size:var(--font-size-sxy);text-align:left;white-space:nowrap;}
#delivery div.dcell:nth-child(1) span {font-size:.9em;text-transform:uppercase;color:var(--color-gry);}
#delivery div.dcell:nth-child(1) b.today {font-size:.8em;margin:0 1vw 0 1vw;color:var(--color-grn);font-weight:600;}
#delivery div.dcell:nth-child(1) b.yeday {font-size:.8em;margin:0 1vw 0 1vw;color:var(--color-gry);font-weight:600;}
#delivery div.dcell:nth-child(2) {font-family:var(--font-crr);font-weight:600;color:var(--color-gry);font-size:var(--font-size-sx);}
#delivery div.dcell:nth-child(3) {font-family:var(--font-crr);font-weight:600;color:var(--color-red);text-align:left;}
#delivery div.dcell:nth-child(4) {color:var(--color-txt);text-align:left;}
#delivery div.dcell:nth-child(5) {color:var(--color-drk-80);text-align:left;}
#delivery div.dcell:nth-child(6) {color:var(--color-grn);font-family:var(--font-crr);font-size:var(--font-size-sx);text-align:left;}
#delivery div.dcell:nth-child(7),
#delivery div.dcell:nth-child(8),
#delivery div.dcell:nth-child(9) {font-weight:600;}
#delivery div.dcell:nth-child(9) {color:var(--color-grn);}
#delivery div.dcell:nth-child(10) {color:var(--color-grn);text-align:right;overflow:visible;}
#delivery div.dlast {text-align:center !important;width:5%;border-radius:0 var(--main-border-radius) 0 0;}
#delivery div.drow:hover {background:var(--color-yel-40);}
#delivery div.drow:hover div.dcell {border-bottom:solid 1px var(--color-nav);}
#delivery div.drow-active {background:var(--color-yel-20);}
#delivery div.drow-active div.dcell {border-bottom:solid 1px var(--color-nav);}
#delivery div.status {position:relative;text-align:center;padding:.4vw 0;cursor:pointer;}
#delivery div.status aside {opacity:0;left:-2vw;}
#delivery div.status:hover aside {opacity:1;left:-10vw;}
#delivery div.price {color:var(--color-grn);}
#delivery button.more {margin:2vw auto;padding:5px 20px;}
    form.delivery-filters {width:80%;max-width:1300px;display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center;align-content:center;border-radius:var(--main-border-radius);margin:20px auto 10px;box-sizing:border-box;padding:20px 10px 10px;background:var(--color-slv);}
    form.delivery-filters fieldset.field-wrap {width:24%;margin:5px;background:var(--color-wht-80);height:auto;padding-bottom:0;}
    form.delivery-filters fieldset.field-nobg {background:none;}
    form.delivery-filters fieldset.field-nobg b {color:var(--color-yel);text-decoration:underline;cursor:pointer;font-weight:300;}
    form.delivery-filters fieldset.field-wrap select {margin:0;padding-top:1.2vw;}
    form.delivery-filters fieldset.field-wrap select:focus ~ label,
    form.delivery-filters fieldset.field-wrap input:focus ~ label {color:var(--color-red-60);top:-2px;}
    form.delivery-filters fieldset.field-wrap label {color:var(--color-grn);top:-2px;}
    form.delivery-filters fieldset.filter-active {background:var(--color-yel-80);}
    form.delivery-filters input {margin-bottom:0;padding-top:1.2vw;}
    form.delivery-filters button {margin:0;font-size:var(--font-size-sx);width:auto !important;}
    form.delivery-filters button svg {fill:var(--color-wht-80);top:5px;width:11%;}
    form.delivery-filters button:hover svg {fill:var(--color-yel-80);top:0;}
    form.delivery-filters h4 {color:var(--color-wht-80);font-size:var(--fnt-size-sy);margin-bottom:5px;}
    form.delivery-filters h4 span {color:var(--color-yel);font-weight:800;}
    div.delivery-buttons {width:90%;max-width:1300px;margin:2vw auto 0;display:flex;justify-content:space-between;}
    div.delivery-buttons aside {width:70%;margin-right:3vw;display:flex;justify-content:space-between;}
    div.delivery-buttons button {width:30%;margin-right:1vw;max-width:220px;min-width:140px;min-height:36px;}
    div.delivery-buttons button.price {font-size:var(--font-size-sx);max-width:240px;min-width:180px;margin-right:0;}
    div.delivery-buttons button.price svg {top:7px;width:10%;}
    div.delivery-buttons button.price:hover svg {top:7px;width:10%;}
div.bottom-buttons {width:100%;display:flex;justify-content:center;}

#full {text-align:center;width:90%;max-width:1300px;margin:2vw auto 3vw;background:var(--color-wht);border-radius:var(--main-border-radius);box-shadow:var(--block-shadow);}
#full svg {fill:var(--color-gry);margin:.5vw auto -.2vw;width:2vw;max-width:30px;}
#full svg.warehouse-out {opacity:.6;}
#full svg.pay {fill:var(--color-txt);width:2.1vw;}
#full svg.almaty {width:3vw;}
#full svg.await {fill:var(--color-txt-80);width:2.1vw;}
#full svg.total {fill:var(--color-txt);width:2vw;}
#full svg.check {fill:var(--color-grn);width:1.8vw;}
#full svg.new {fill:var(--color-red);width:1.6vw;}
#full svg.info {position:absolute;right:2vw;top:1.1vw;fill:var(--color-blu);width:1.6vw;}
#full div.dtable {font-family:var(--font-capt);font-size:var(--font-size-sxx);border-radius:var(--main-border-radius);}
#full div.drow {cursor:pointer;}
#full div.dhead {background:var(--color-grn);text-align:center;white-space:nowrap;color:var(--color-wht-80);border-bottom:none;padding:1vw 1vw 1vw 1vw;font-size:var(--font-size-sx);padding-top:.6vw;}
#full div.dhead:nth-child(1) {width:10%;border-radius:var(--main-border-radius) 0 0 0;}
#full div.dhead:nth-child(2) {width:5%;}
#full div.dhead:nth-child(3) {width:12%;text-align:left;}
#full div.dhead:nth-child(4) {width:25%;text-align:left;}
#full div.dhead:nth-child(5) {width:15%;text-align:left;}
#full div.dhead:nth-child(6) {width:10%;text-align:left;}
#full div.dhead:nth-child(7) {width:12%;}
#full div.dhead:nth-child(8) {width:5%;border-radius:0 var(--main-border-radius) 0 0;}
#full div.dcell {text-align:center;align-content:center;color:var(--color-gry);white-space:nowrap;overflow:hidden;text-overflow:ellipsis;border-bottom:solid 1px var(--color-blu);padding:.5vw 1vw;}
#full div.dcell:nth-child(1) {color:var(--color-drk);font-size:var(--font-size-sxy);white-space:nowrap;}
#full div.dcell:nth-child(1) span {font-size:.9em;text-transform:uppercase;color:var(--color-gry);}
#full div.dcell:nth-child(1) b.today {font-size:.8em;margin:0 1vw 0 1vw;color:var(--color-grn);font-weight:600;}
#full div.dcell:nth-child(1) b.yeday {font-size:.8em;margin:0 1vw 0 1vw;color:var(--color-gry);font-weight:600;}
#full div.dcell:nth-child(2) {color:var(--color-grn);font-size:var(--font-size-sx);}
#full div.dcell:nth-child(3) {color:var(--color-red);text-align:left;overflow:visible;}
#full div.dcell:nth-child(4) {color:var(--color-txt);text-align:left}
#full div.dcell:nth-child(5) {color:var(--color-red);text-align:left;}
#full div.dcell:nth-child(6) {color:var(--color-red);text-align:left;overflow:visible;}
#full div.dcell:nth-child(2) img {width:4vw;height:2.5vw;object-fit:cover;transition:var(--transition-tip);cursor:pointer;}
#full div.dcell:nth-child(2) img:hover {z-index:2;transform:scale(2);}
#full div.dcell:nth-child(3) img {width:4vw;height:2.5vw;object-fit:cover;transition:var(--transition-tip);cursor:pointer;}
#full div.dcell:nth-child(3) img:hover {z-index:2;transform:scale(2);}
#full div.dlast {text-align:center !important;width:5%;overflow:visible;}
#full div.drow:hover {background:var(--color-yel-40);}
#full div.drow:hover div.dcell {border-bottom:solid 1px var(--color-nav);}
#full div.drow-active {background:var(--color-yel-20);}
#full div.drow-active div.dcell {border-bottom:solid 1px var(--color-nav);}
#full div.status {position:relative;text-align:center;padding:.4vw 0;cursor:pointer;}
#full div.status aside {opacity:0;left:-2vw;}
#full div.status:hover aside {opacity:1;left:-10vw;}
#full div.price {color:var(--color-grn);}
#full button.more {margin:2vw auto;padding:5px 20px;}
#full button.price {margin:2vw 0 2vw auto;float:right;padding:5px 20px;}
#full button.full {position:relative;margin:2vw auto 2vw;padding:5px 20px;min-width:250px;}
#full button.full svg {top:-.2vw;width:1.5vw;fill:var(--color-wht-60);}

#order {width:90%;min-width:360px;max-width:1200px;margin:2vw auto;box-shadow:var(--block-shadow);background:var(--color-wht);border-radius:var(--main-border-radius);}
#order div.head {display:flex;justify-content:space-between;align-items:flex-end;height:3.5vw;width:100%;text-shadow:0px 0px 6px var(--color-drk);}
#order div.head h4 {width:45%;margin-bottom:.4vw;text-align:right;font-size:var(--font-size-sx);padding:0 2vw .5vw 0;color:var(--color-wht-80);font-weight:400;}
#order div.head h3 {width:95%;margin-bottom:.4vw;position:relative;font-size:var(--font-size-sm);text-shadow:0px 0px 1px var(--color-drk);transition:var(--transition-tip);}
#order div.head h3 span {float:none;display:inline-block;margin:0 .5vw;color:var(--color-yel-80);}
#order div.body {width:90%;margin:2vw auto 3vw;}
#order div.body h3 {font-size:var(--font-size-sx); margin:1vw auto 1vw 0;}
#order div.body div.flex {display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;}
#order div.body div.images {align-items:center;justify-content:center;}
#order div.body div.images img {width:30%;box-shadow:var(--blck-shadow);border-radius:var(--main-border-radius);transition:var(--transition-tip);cursor:pointer;}
#order div.body div.images img:hover {z-index:2;transform:scale(2);}
#order div.wrap {width:30%;max-width:400px;}
#order ul {list-style-position:inside;margin-bottom:3vw;}
#order ul li {font-size:var(--font-size-sx);color:var(--color-drk-80);width:100%;display:flex;justify-content:space-between;align-items:flex-end;margin-bottom:.5vw;overflow:hidden;}
#order ul li b {color:var(--color-nav);font-size:var(--font-size-sy);text-shadow:none;font-weight:400;white-space:nowrap;margin:0 0 .3vw auto;}
#order ul li i {width:100%;height:1vw;border-bottom:dotted 1px var(--color-gry);margin:0 -20vw .2vw .2vw;overflow:hidden;}
#order ul li span {white-space:nowrap;margin-bottom:.1vw;}
#order ul li span.caption {font-weight:300;color:var(--color-txt);}
#order ul li.client {text-transform:capitalize;font-size:var(--font-size-sy);font-weight:500;color:var(--color-grn);}
#order ul b.client {text-transform:capitalize;font-weight:500;}
#order ul.price {margin-top:5vw;}
#order div.price-total {margin:2vw 1vw 1vw auto;}
#order div.price-total ul li b {color:var(--color-drk-80);}
#order div.price-total ul li.last {color:var(--color-red);font-size:var(--font-size-sl);font-weight:800;}
#order div.price-total ul li.last b {color:var(--color-grn);font-size:var(--font-size-ss);font-weight:600;}
#order div.order-range {width:80%;margin:2vw auto;transform:scale(1.2);}
#order div.order-range div.flex {width:100%;margin:0 auto .5vw;display:flex;justify-content:space-between;}
#order div.order-range div.flex div {font-size:var(--font-size-sxy);text-align:center;color:var(--color-gry-60);}
#order div.order-range div.flex div span {font-size:1.1em;color:var(--color-gry-60);}
#order div.order-range div.flex div svg.arrows {width:3vw;fill:var(--color-gry-20);}
#order div.order-range div.flex div.ready {font-size:var(--font-size-sxy);text-align:center;color:var(--color-grn-80);}
#order div.order-range div.flex div.ready span {font-size:1.1em;color:var(--color-red-80);}
#order div.order-range div.flex div.ready svg.arrows {fill:var(--color-grn-80);}


#profile {display:flex;flex-wrap:wrap;justify-content:space-around;align-items:flex-start;align-content:flex-start;margin:auto;width:100%;height:100%;max-width:1400px;}
#profile div.head {position:relative;}
#profile div.head p {color:var(--color-wht-60);margin:auto 1vw .4vw auto;}
#profile div.head div.flex {width:60%;position:absolute;bottom:.2vw;right:1vw;font-size:var(--font-size-sl);}
#profile div.head div.flex span {color:var(--color-yel-80);}
#profile div.head div.flex aside {position:relative;}
#profile div.head div.flex svg {width:1.5vw;fill:var(--color-wht-80);position:absolute;top:.2vw;left:-2.5vw;}
#profile div.half-comp-wrap {margin:3vw auto 0;width:80%;min-width:900px;max-width:1000px;}
#profile form {width:95%;margin:1vw auto 0;display:flex;flex-wrap:wrap;justify-content:flex-end;align-items:flex-end;}
#profile form p {position:relative;margin-bottom:1.5vw;width:90%;display:block;margin:auto;text-align:center;}
#profile form p.copy {margin-bottom:2.5vw;color:var(--color-drk-60);}
#profile form p.copy b {color:var(--color-drk);}
#profile form a {display:block;margin-bottom:2vw;}
#profile form fieldset {margin:0 auto 2vw;width:45%;min-width:180px;}
#profile form fieldset.radio-box-wrap {height:4.2vw;margin-top:-.7vw;}
#profile form fieldset div.flex {margin-bottom:0;}
#profile form fieldset div.flex label {width:90%;font-size:var(--font-size-sxx);}
#profile form fieldset div.flex input[type=checkbox] {margin-top:.5vw;}
#profile form fieldset svg.copy {position:absolute;right:.5vw;top:.3vw;fill:var(--color-txt-80);width:1.5vw;cursor:pointer;}
#profile form fieldset svg.copy:hover {fill:var(--color-txt);}
#profile form.mb-1 {margin-bottom:0;}
#profile div.options {position:relative;margin:2vw auto 2vw;width:90%;display:block;}
#profile div.options div.message-box {position:absolute;top:-1vw;right:0vw;color:var(--color-drk-70);}
#profile div.options div.message-box span {display:block;}
#profile div.options div.message-box span u {cursor:pointer;margin-top:1vw;margin-right:5vw;}
#profile div.options div.message-box span u.last {margin-right:1vw;}
#profile div.options div.message-box span u:hover {color:var(--color-red);}
#profile div.options div.message-box span u.active  {color:var(--color-red);}
#profile div.options h3 {text-transform:none;width:100%;display:block;}
#profile div.options h3 span {margin-left:1vw;}
#profile svg.attention {width:2vw;fill:var(--color-red);position:absolute;top:-.6vw;right:-1vw;}
#profile legend {padding:.3vw 0 0 .5vw;}
#profile form button {margin:1vw auto 2.5vw !important;width:45%;min-width:200px;max-width:600px;}
#profile form button svg {width:2vw;top:.5vw;}
#profile form button.right {margin:-3vw auto 2vw;}
#profile div.align-center {margin-top:-1vw;}
#profile div.align-center fieldset {margin-left:1.5vw;}
#profile div.flex {width:100%;justify-content:flex-end;}
#profile div.flex aside {width:40%;padding-bottom:.2em;}
#profile div.flex span {margin-left:1vw;font-size:1em;}
#profile div.flex p {fill:var(--color-drk-20);}
#profile div.flex p b {fill:var(--color-drk-90);}

#chart canvas {margin:auto;width:90%;min-width:1080px;max-width:1300px;}
#chart ul.years {z-index:2;margin-top:1vw;position:absolute;top:0;left:0;right:0;text-align:center;font-size:var(--font-size-sl);}
#chart ul.years li {display:inline-block;list-style-type:none;margin:2vh 2vw 0;cursor:pointer;color:var(--color-txt-60);}
#chart ul.years li:hover {color:var(--color-txt);}
#chart ul.years li.active {color:var(--color-txt);}


#users {text-align:center;width:90%;max-width:1300px;margin:2vw auto 3vw;}
#users div.dtable {background:var(--color-wht);border-radius:var(--main-border-radius);box-shadow:var(--block-shadow);font-family:var(--font-capt);font-size:var(--font-size-sxx);border-radius:var(--main-border-radius);}
#users div.drow {cursor:pointer;}
#users div.drow:hover {background:var(--color-yel-20);}
#users div.dhead {background:var(--color-grn);text-align:center;color:var(--color-wht-80);border-bottom:none;padding:1vw 1vw 1vw 1vw;font-size:var(--font-size-sx);padding-top:.6vw;}
#users div.dhead:nth-child(1) {width:15%;border-radius:var(--main-border-radius) 0 0 0;}
#users div.dhead:nth-child(2) {width:8%;}
#users div.dhead:nth-child(3) {text-align:left;}
#users div.dhead:nth-child(4) {width:8%;text-align:left;}
#users div.dhead:nth-child(5) {width:12%;}
#users div.dhead:nth-child(6) {width:15%;}
#users div.dhead:nth-child(7) {width:8%;}
#users div.dcell {text-align:center;align-content:center;color:var(--color-gry);border-bottom:solid 1px var(--color-blu);padding:.5vw 1vw;}
#users div.dcell:nth-child(1) {color:var(--color-drk);font-size:var(--font-size-sxy);text-align:left;white-space:nowrap;}
#users div.dcell:nth-child(1) span {font-size:.9em;text-transform:uppercase;color:var(--color-gry);}
#users div.dcell:nth-child(1) b.today {font-size:.8em;margin:0 1vw 0 1vw;color:var(--color-grn);font-weight:600;}
#users div.dcell:nth-child(1) b.yeday {font-size:.8em;margin:0 1vw 0 1vw;color:var(--color-gry);font-weight:600;}
#users div.dcell:nth-child(2) {font-family:var(--font-crr);font-weight:600;color:var(--color-gry);font-size:var(--font-size-sx);}
#users div.dcell:nth-child(3) {color:var(--color-txt);text-align:left;text-transform:capitalize;}
#users div.dcell:nth-child(4) {color:var(--color-red);text-align:left;}
#users div.dcell:nth-child(5) {font-size:var(--font-size-sx);}
#users div.dcell:nth-child(6) {color:var(--color-drk);font-size:var(--font-size-sxy);text-align:left;}
#users div.dcell:nth-child(6) span {font-size:.9em;text-transform:uppercase;color:var(--color-gry);}
#users div.dcell:nth-child(6) b.today {font-size:.8em;margin:0 1vw 0 1vw;color:var(--color-grn);font-weight:600;}
#users div.dcell:nth-child(6) b.yeday {font-size:.8em;margin:0 1vw 0 1vw;color:var(--color-gry);font-weight:600;}
#users div.dcell:nth-child(7) {color:var(--color-grn);text-align:right;}
#users div.dlast {text-align:center !important;width:5%;border-radius:0 10px 0 0;}
#users div.drow:hover {background:var(--color-yel-40);}
#users div.drow:hover div.dcell {border-bottom:solid 1px var(--color-nav);}
#users div.drow-active {background:var(--color-yel-20);}
#users div.drow-active div.dcell {border-bottom:solid 1px var(--color-nav);}
#users div.status {position:relative;text-align:center;padding:.4vw 0;cursor:pointer;}
#users div.status aside {opacity:0;left:-2vw;}
#users div.status:hover aside {opacity:1;left:-10vw;}
#users div.price {color:var(--color-grn);}
#users button.more {margin:2vw auto;padding:5px 20px;}
#users form {width:100%;max-width:40vw;margin:0 auto 1vw;position:relative;}
#users form svg {width:1.7vw;position:absolute;left:.4vw;top:.5vw;fill:var(--color-nav-80);cursor:pointer;}
#users form svg:hover {fill:var(--color-grn);}
#users form input {border-radius:0 0 0 var(--main-border-radius);width:100%;max-width:40vw;background:var(--color-gry-20);padding:.5vw .5vw .5vw 2.5vw;box-shadow:0px 0px 9px var(--color-wht-60);border-top:solid 2px var(--color-nav);border-right:solid 2px var(--color-nav);}
#users form input:focus {border-radius:0 0 0 var(--main-border-radius);box-shadow:none;background:var(--color-yel-40);}
#users form input::placeholder {color:var(--color-nav-80) !important;}

#cargo {display:flex;flex-wrap:wrap;justify-content:space-between;width:90%;max-width:1300px;margin:auto;}
#cargo div.top-comp-wrap {width:100%;margin-top:1.5vw;}
#cargo div.white-price-wrap {width:100%;}
#cargo div.white-price-wrap form {width:100%;}
#cargo div.white-price-wrap div.flex-white {width:90%;margin:1vw auto 0;display:flex;justify-content:space-between;align-items:center;}
#cargo div.white-price-wrap div.flex-white fieldset.field-wrap {max-width:180px;}
#cargo div.white-price-wrap button {margin:2vw 0 2vw auto;}
#cargo div.white-price-wrap div.head {background:var(--color-slv);}
#cargo div.white-price-wrap div.head h3 span {position:absolute;right:10px;top:-5px;font-size:1.4em;}
#cargo p.error {color:var(--color-red-80);font-size:var(--font-size-sl);text-align:center;}
#cargo div.dleft {width:40%;min-width:420px;}
#cargo div.drght {width:56%;margin-left:2vw;}
#cargo form {display:flex;flex-wrap:wrap;justify-content:space-around;align-items:flex-start;margin:.5vw auto 0;}
#cargo form fieldset {width:46%;min-width:120px;margin:1vw 1vw 0 0;}
#cargo form fieldset.field-wrap {margin-bottom:.5vw;}
#cargo form fieldset.field-upload {z-index:2;opacity:0;}
#cargo form fieldset button {width:100%;min-width:140px;margin-top:.5vw;}
#cargo form fieldset button svg {top:.75vw;width:1.5vw;}
#cargo form fieldset.field-wrap-lg {width:100%;display:flex;justify-content:space-around;}
#cargo form fieldset.field-wrap-lg fieldset {width:30%;min-width:80px;margin:0 auto;}
#cargo form fieldset.cat-pic {width:44%;text-align:center;margin-top:-5vw;}
#cargo form fieldset.cat-pic img {width:120px;margin:auto;}


#cargo ul {display:flex;flex-wrap:wrap;justify-content:space-between;font-size:var(--font-size-sy);margin:1vw auto 0;width:96%;color:var(--color-drk-80);}
#cargo ul li {width:28%;min-width:120px;display:inline-block;text-align:left;margin:0 .5vw .5vw;cursor:pointer;}
#cargo ul li span {color:var(--color-grn);float:right;}
#cargo ul li.active {color:var(--color-red);}
#cargo div.dtable {width:100%;font-family:var(--font-capt);border-radius:var(--main-border-radius);}
#cargo div.drow:hover {background:var(--color-yel-30);}
#cargo div.active {background:var(--color-yel-30) !important;}
#cargo div.dhead {background:var(--color-grn);text-align:center;white-space:nowrap;color:var(--color-wht-80);font-size:var(--font-size-sx);border-bottom:none;padding:1vw 1vw 1vw 1vw;padding-top:1vw;}
#cargo div.dhead:nth-child(1) {text-align:left;padding-left:1vw;}
#cargo div.dhead:nth-child(2) {width:20%;}
#cargo div.dhead:nth-child(3) {width:20%;}
#cargo div.dhead:nth-child(4) {width:20%;padding-right:1vw;}
#cargo div.dcell {text-align:center;align-content:center;color:var(--color-grn);font-size:var(--font-size-sx);white-space:nowrap;overflow:hidden;text-overflow:ellipsis;border-bottom:solid 1px var(--color-blu);padding:.5vw 1vw;}
#cargo div.dcell:nth-child(1) {text-align:left;padding-left:1vw;color:var(--color-drk);}
#cargo div.dcell:nth-child(3) {color:var(--color-red-80);}
#cargo div.dcell:nth-child(4) {color:var(--color-drk-80);padding-right:1vw;}
#cargo div.dcell:nth-child(5) {width:3%;}
#cargo div.dcell:nth-child(5) svg {width:1.5vw;fill:var(--color-grn-80);cursor:pointer;}
#cargo div.dcell:nth-child(6) {width:3%;}
#cargo div.dcell:nth-child(6) svg {width:1.5vw;fill:var(--color-red-80);cursor:pointer;}


/*----------------------------- СРЕДНИЕ ЭКРАНЫ [ 1400, 1200 px ] -------------------------------------*/

@media (min-width:1600px){
    #content {padding-bottom:2vw;left:0;right:0;top:11vw;bottom:4vh;z-index:100;overflow-x:hidden;}
    #cargo form fieldset.field-wrap {margin-bottom:.2vw !important;}
    #profile div.half-comp-wrap {margin:3vw auto 0;width:80%;min-width:900px;max-width:1200px;}
}

@media (max-width:1100px){
    div.head {height:5vh;}
    p.display {display:none !important;}
    #content {left:0;right:0;top:12vw;bottom:5vw;}
    
    #home {width:90%;max-width:1000px;min-width:900px;}
    #home ul {margin:3vh auto;width:90%;min-width:400px;max-width:500px;display:flex;flex-wrap:wrap;justify-content:space-between;}
    #home ul li {margin:2vh 1vw;padding:1.5vw 1vw .5vw;width:42%;max-width:200px;min-width:180px;}
    #home ul li svg {width:40%;min-width:60px;max-width:100px;margin:1vw auto;}
    #home ul li span {font-size:var(--font-size-ss);display:block;white-space:nowrap;}
    #home div.dtable {margin-top:4vw;display:block;}
    #home div.drow {display:block;}
    #home div.dcell {display:block;width:100%;}
    #home button.register {width:40%;margin:2vw 30% 1vw 30%;}

    #form-home {margin:0 auto;width:90%;min-width:400px;max-width:500px;padding-bottom:2vw;}
    #form-home form {width:90%;margin:0 auto;}
    #form-home div.flex {justify-content:space-around;}
    #form-home div.flex a {white-space:nowrap;}
    
    #account {display:flex;justify-content:center;align-items:flex-start;position:absolute;width:100%;height:100%;}
    #account div.half-comp-wrap {width:46%;min-width:400px;max-width:600px;}
    
    #delivery {width:96%;margin:3vh auto;}
    #delivery div.dtable {font-size:var(--font-size-sy)}
    #delivery div.dhead {font-size:var(--font-size-sl);}
    #delivery div.dhead:nth-child(1) {width:10%;}
    #delivery div.dhead:nth-child(2) {width:10%;}
    #delivery div.dhead:nth-child(4) {width:10%;}
    #delivery div.dhead:nth-child(5) {width:15%;}
    #delivery div.dhead:nth-child(6) {display:none;}
    #delivery div.dhead:nth-child(7) {display:none;}
    #delivery div.dhead:nth-child(8) {display:none;}
    #delivery div.dhead:nth-child(9) {width:10%;}
    #delivery div.dcell {white-space:nowrap;padding:.5vw 1vw;}
    #delivery div.dcell:nth-child(1) {font-size:var(--font-size-sx);}
    #delivery div.dcell:nth-child(6) {display:none;}
    #delivery div.dcell:nth-child(7) {display:none;}
    #delivery div.dcell:nth-child(8) {display:none;}
    #delivery div.dlast {width:10%;}
    #delivery div.status {padding:.4vw 0;}
    #delivery div.status aside {left:-2vw;}
    #delivery div.status:hover aside {left:-10vw;}
    #delivery button.more {margin:2vw auto;}
    #delivery button.deli {margin:4vw auto;}
    #delivery button.deli svg {width:6vw;top:1.7vw;}
    div.delivery-buttons button.price svg {top:4px;width:10%;}

    #full {width:96%;margin:3vh auto;}
    #full svg {margin:0 auto -.6vw;width:2.5vw;}
    #full svg.check {width:2.3vw;}
    #full div.dtable {font-size:var(--font-size-sy)}
    #full div.dhead {font-size:var(--font-size-sl);}
    #full div.dcell {white-space:nowrap;padding:.5vw 1vw;}
    #full div.dcell:nth-child(1) {font-size:var(--font-size-sx);}
    #full div.status {padding:.4vw 0;}
    #full div.status aside {left:-2vw;}
    #full div.status:hover aside {left:-10vw;}
    #full button.more {margin:4vw auto;}
    #full button.full {margin:4vw auto;}
    #full button.full svg {width:6vw;top:1.7vw;}

    #order {width:90%;min-width:360px;max-width:900px;margin:2vw auto;}
    #order div.head {display:flex;justify-content:space-between;align-items:flex-end;height:3.5vw;width:100%;}
    #order div.head h4 {width:45%;margin-bottom:.4vw;text-align:right;font-size:var(--font-size-sx);padding:0 2vw .5vw 0;}
    #order div.head h3 {width:95%;margin-bottom:0;font-size:var(--font-size-sl);}
    #order div.head h3 span {margin:0 .5vw;}
    #order div.body {width:90%;margin:2vw auto 3vw;}
    #order div.body h3 {font-size:var(--font-size-ss);margin:1vw auto 1vw 0;}
    #order div.body div.flex {display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;}
    #order div.wrap {width:45%;max-width:450px;}
    #order ul {margin-bottom:3vw;}
    #order ul li {font-size:var(--font-size-sm);width:100%;display:flex;justify-content:space-between;align-items:flex-end;margin-bottom:.5vw;overflow:hidden;}
    #order ul li b {font-size:var(--font-size-sl);white-space:nowrap;margin:0 0 .3vw auto;}
    #order ul li i {width:100%;height:1vw;margin:0 -20vw .2vw .2vw;}
    #order ul li span {white-space:nowrap;margin-bottom:.1vw;}
    #order ul li.client {font-size:var(--font-size-sl);}
    #order ul b.client {font-weight:500;}
    #order ul.price {margin-top:5vw;}
    #order div.price-total {margin:2vw 1vw 1vw auto;}
    #order div.price-total ul li.last {font-size:var(--font-size-ss);}
    #order div.price-total ul li.last b {font-size:var(--font-size-md);}
    #order div.order-range {width:80%;margin:2vw auto;transform:scale(1.2);}
    #order div.order-range div.flex {width:100%;margin:0 auto .5vw;display:flex;justify-content:space-between;}
    #order div.order-range div.flex div {font-size:var(--font-size-sxy);}
    #order div.order-range div.flex div span {font-size:1.1em;}
    #order div.order-range div.flex div svg.arrows {width:3vw;}
    #order div.order-range div.flex div.ready {font-size:var(--font-size-sxy);}
    #order div.order-range div.flex div.ready span {font-size:1.1em;}
    #order div.order-range div.flex div.ready svg.arrows {width:3vw;}
    
    #profile {display:block;margin:auto;max-width:1100px;}
    #profile div.head p {margin:auto 1vw .4vw auto;}
    #profile div.head div.flex {width:60%;position:absolute;bottom:.5vw;right:1vw;font-size:var(--font-size-sl);}
    #profile div.head div.flex aside {position:relative;}
    #profile div.head div.flex svg {width:1.5vw;top:0;left:-2vw;}
    #profile div.half-comp-wrap {width:80%;min-width:500px;max-width:800px;}
    #profile div.dtable {display:block;}
    #profile div.drow {display:block;}
    #profile div.dcell {display:block;}
    #profile form {margin:2vh auto;}
    #profile form p {margin-bottom:1.5vh;}
    #profile form p.copy {margin-bottom:1vw;}
    #profile form a {margin-bottom:1.5vh;}
    #profile form fieldset {margin:0 auto 1vw;width:45%;min-width:180px;}
    #profile form fieldset.radio-box-wrap {height:4.2vw;margin-top:-.7vw;}
    #profile form fieldset div.flex {margin-bottom:0;}
    #profile form fieldset div.flex label {width:90%;font-size:var(--font-size-sl);}
    #profile form fieldset div.flex input[type=checkbox] {margin-top:.5vw;}
    #profile form fieldset svg.copy {position:absolute;right:.5vw;top:1vh;width:2vw;}
    #profile form.mb-1 {margin-bottom:2vw;}
    #profile div.options {position:relative;margin:2vw auto 2vw;width:90%;display:block;}
    #profile div.options div.message-box {position:absolute;top:-1vw;right:0vw;color:var(--color-drk-70);}
    #profile div.options div.message-box span {display:block;}
    #profile div.options h3 {text-transform:none;width:100%;display:block;}
    #profile svg.attention {width:3vw;top:-.6vw;right:-1vw;}
    #profile legend {padding:.3vw 0 0 .5vw;}
    #profile form button {margin:0 auto 3vw !important;}
    #profile form button svg {width:2vw;top:.5vw;}
    #profile form button.right {margin:-3vw auto 2vw;}
    #profile div.align-center {margin-top:-1vw;}
    #profile div.align-center fieldset {margin-left:1.5vw;}
    #profile div.flex {width:100%;justify-content:space-between;}
    #profile div.flex p {fill:var(--color-drk-20);font-size:var(--font-size-sxx);}
    #profile div.flex p b {fill:var(--color-drk-90);}
    
    #chart canvas {margin:auto;width:90%;min-width:1080px;max-width:1300px;}
    #chart ul.years {z-index:2;margin-top:1vw;position:absolute;top:0;left:0;right:0;text-align:center;font-size:var(--font-size-sl);}
    #chart ul.years li {display:inline-block;list-style-type:none;margin:2vh 2vw 0;cursor:pointer;color:var(--color-txt-60);}
    #chart ul.years li:hover {color:var(--color-txt);}
    #chart ul.years li.active {color:var(--color-txt);}
    
    #users {width:96%;margin:2vw auto 3vw;}
    #users div.dtable {font-size:var(--font-size-sy);}
    #users div.dhead {padding:1vw 1vw 1vw 1vw;font-size:var(--font-size-sl);}
    #users div.dcell {padding:.5vw 1vw;white-space:nowrap;}
    #users div.dcell:nth-child(1) {font-size:var(--font-size-sx);}
    #users div.dcell:nth-child(5) {font-size:var(--font-size-sy);}
    #users div.dcell:nth-child(6) {font-size:var(--font-size-sx);}
    #users div.dlast {width:5%;}
    #users div.drow:hover {background:var(--color-yel-40);}
    #users div.drow:hover div.dcell {border-bottom:solid 1px var(--color-nav);}
    #users div.drow-active {background:var(--color-yel-20);}
    #users div.drow-active div.dcell {border-bottom:0 !important;}
    #users div.status {position:relative;text-align:center;padding:.4vw 0;cursor:pointer;}
    #users div.status aside {opacity:0;left:-2vw;}
    #users div.status:hover aside {opacity:1;left:-10vw;}
    #users div.price {color:var(--color-grn);}
    #users button.more {margin:2vw auto;}
    #users form {width:100%;max-width:40vw;margin:0 auto 1vw;position:relative;}
    #users form svg {width:1.7vw;position:absolute;left:.4vw;top:.5vw;fill:var(--color-nav-80);cursor:pointer;}
    #users form svg:hover {fill:var(--color-grn);}
    #users form input {border-radius:0 0 0 var(--main-border-radius);width:100%;max-width:40vw;background:var(--color-gry-20);padding:.5vw .5vw .5vw 2.5vw;box-shadow:0px 0px 9px var(--color-wht-60);border-top:solid 2px var(--color-nav);border-right:solid 2px var(--color-nav);}
    #users form input:focus {border-radius:0 0 0 var(--main-border-radius);box-shadow:none;background:var(--color-yel-40);}
    #users form input::placeholder {color:var(--color-nav-80) !important;}
}



/*----------------------------- МАЛЫЕ ЭКРАНЫ [ 800 px ] -------------------------------------*/

@media (max-width:800px){
    div.head {height:auto;border-radius:0;padding:1vw 2vw 1.5vw;}
    div.head h3 {font-size:var(--font-size-sm);width:95%;position:relative;bottom:0;left:3vw;right:3vw;padding:3vw 0 2vw;}
    div.head h3 b {font-weight:300;font-size:.9em;}
    div.head h3 span {color:var(--color-yel);float:right;text-transform:none;}
    div.head div.dtable {margin:3vw auto 1vw;}
    div.head .order-delete {display:none;}
    div.body {position:relative;margin:auto;}
    div.body div.dtable {margin:4vw auto 6vw;}

    #content {position:relative;left:0;right:0;top:0;bottom:auto;min-height:89.3vh;padding-bottom:0;background:var(--color-wht);}

    #chart {display:none;}

    #profile {display:block;margin:auto;max-width:900px;}
    #profile div.head p {margin:auto 1vw .4vw auto;}
    #profile div.head div.flex {display:none;}
    #profile div.head div.flex aside {position:relative;}
    #profile div.head div.flex svg {width:1.5vw;top:0;left:-2vw;}
    #profile div.half-comp-wrap {margin:0 auto 0;width:100%;min-width:320px;max-width:800px;}
    #profile div.dtable {display:block;}
    #profile div.drow {display:block;}
    #profile div.dcell {display:block;}
    #profile form {margin:8vw auto 6vw;}
    #profile form p {margin-bottom:2wv;}
    #profile form p.copy {margin-bottom:1vw;}
    #profile form a {margin-bottom:2vw;}
    #profile form fieldset {margin:0 auto 1vw;width:90%;min-width:300px;}
    #profile form fieldset.radio-box-wrap {height:4.2vw;margin-top:-.7vw;}
    #profile form fieldset div.flex {margin-bottom:0;}
    #profile form fieldset div.flex label {width:90%;font-size:var(--font-size-sl);}
    #profile form fieldset div.flex input[type=checkbox] {margin-top:.5vw;}
    #profile form fieldset svg.copy {position:absolute;right:2vw;top:2vw;width:5vw;}
    #profile form.mb-1 {margin:2vw auto;}
    #profile div.options {position:relative;margin:4vw auto 3vw;width:90%;display:block;}
    #profile div.options div.message-box {position:absolute;top:-1vw;right:0vw;color:var(--color-drk-70);}
    #profile div.options div.message-box span {display:block;margin-bottom:1.2vw;}
    #profile div.options h3 {text-transform:none;width:100%;display:block;}
    #profile div.options h3 span {margin-left:0;display:block;}
    #profile svg.attention {width:7vw;top:-6vw;right:-4vw;}
    #profile legend {padding:.3vw 0 0 .5vw;}
    #profile form button {margin:3vw auto 6vw !important;width:90%;min-width:240px;max-width:320px;}
    #profile form button svg {width:6vw;top:1.5vw;right:2vw;}
    #profile form button.right {margin:-3vw auto 2vw;}
    #profile div.align-center {margin-top:-1vw;}
    #profile div.align-center fieldset {margin-left:1.5vw;}
    #profile div.flex {width:100%;justify-content:space-between;}
    #profile div.flex p {fill:var(--color-drk-20);font-size:var(--font-size-sxx);}
    #profile div.flex p b {fill:var(--color-drk-90);}

    #delivery {width:96%;margin:5vw auto;background:none;border-radius:none;box-shadow:none;}
    #delivery svg {margin:0 auto -.6vw;width:8vw;max-width:32px;}
    #delivery svg.await {width:8vw;}
    #delivery svg.almaty {width:10vw;}
    #delivery svg.total {width:8vw;}
    #delivery svg.check {width:6vw;}
    #delivery svg.close {width:8vw;}
    #delivery svg.info {right:2vw;top:1.1vw;width:8vw;}
    #delivery div.dtable {width:95%;font-size:var(--font-size-sy);background:none;}
    #delivery div.drow {position:relative;box-shadow:0px 0px 6px var(--color-drk-80);background:var(--color-wht);border-radius:var(--main-border-radius);margin:2vw auto 7vw;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;align-content:flex-start;}
    #delivery div.drow-active {background:var(--color-yel-20);}
    #delivery div.drow-active div.dcell {border:0;}
    #delivery div.rhead {display:none;}
    #delivery div.dcell {white-space:nowrap;padding:.5vw 1vw .5vw 2vw;margin-bottom:1vw;border-bottom:none;}
    #delivery div.dcell:nth-child(1) {width:100%;text-align:left;text-shadow:var(--text-shadow);font-size:var(--font-size-md);color:var(--color-wht-80);border-radius:var(--main-border-radius) var(--main-border-radius) 0 0;background:var(--color-grn-80);}
    #delivery div.dcell:nth-child(1) span {font-size:.8em;color:var(--color-wht-80);margin-left:2vw;}
    #delivery div.dcell:nth-child(1) b.today,
    #delivery div.dcell:nth-child(1) b.yeday {font-size:.7em;margin:0 1vw 0 1vw;color:var(--color-yel);font-weight:600;}
    #delivery div.dcell:nth-child(2) {width:40%;color:var(--color-red);text-align:left;}
    #delivery div.dcell:nth-child(3) {position:absolute;right:.5vw;top:0;width:50%;font-size:var(--font-size-sx);text-shadow:var(--text-shadow);color:var(--color-wht-80);text-align:right;}
    #delivery div.dcell:nth-child(4) {width:90%;color:var(--color-red);text-align:left;}
    #delivery div.dcell:nth-child(5) {width:80%;color:var(--color-red);text-align:left;}
    #delivery div.dcell:nth-child(9) {display:none;}
    #delivery div.dcell:nth-child(2) span,
    #delivery div.dcell:nth-child(4) span,
    #delivery div.dcell:nth-child(5) span {color:var(--color-txt-80);}
    #delivery div.dlast {width:16%;display:block !important;margin-top:-4vw;color:var(--color-grn);text-align:right;}
    #delivery div.dlast svg {width:80%;}
    #delivery div.drow:hover div.dcell {border-bottom:none;}
    #delivery div.status {padding:.4vw 0;}
    #delivery div.status aside {left:-2vw;}
    #delivery div.status:hover aside {left:-10vw;}
    #delivery button.more {margin:2vw auto;}
        form.delivery-filters {padding-top:20px;}
        form.delivery-filters fieldset.field-wrap {width:90%;min-width:240px;margin:5px auto;text-align:center;}
        form.delivery-filters fieldset.field-wrap select:focus ~ label,
        form.delivery-filters fieldset.field-wrap input:focus ~ label {color:var(--color-yel);top:-24px;}
        form.delivery-filters fieldset.field-wrap label {color:var(--color-wht);top:-24px;}
        form.delivery-filters button svg {display:none;}
        form.delivery-filters h4 {font-size:1.5em;margin-bottom:5px;}
        form.delivery-filters h4 span {color:var(--color-yel);font-weight:800;}
        form.delivery-filters fieldset.field-nobg b {font-size:1.2em;}
    div.delivery-buttons {margin:3vw auto -1vw !important;max-width:400px;display:flex;flex-wrap:wrap;justify-content:center;}
    div.delivery-buttons button {width:28%;text-align:center;min-width:105px;max-width:120px;margin:1vw;}
    div.delivery-buttons button svg {display:none;}
    div.delivery-buttons p.request-result {width:100%;font-size:var(--font-size-sl);}
    div.delivery-buttons aside {width:100%;margin-right:0;}
    div.delivery-buttons button.price {display:none;width:100%;max-width:400px;}

    #full {width:100%;margin:0 auto;background:none;border-radius:none;box-shadow:none;}
    #full svg {margin:0 auto -.6vw;width:8vw;max-width:42px;}
    #full svg.new {width:8vw !important;}
    #full svg.pay {width:9vw !important;}
    #full svg.check {width:8vw !important;}
    #full svg.info {right:2vw;top:1.1vw;width:8vw;}
    #full div.dtable {width:95%;font-size:var(--font-size-sy);}
    #full div.drow {position:relative;box-shadow:0px 0px 6px var(--color-drk-80);background:var(--color-wht);border-radius:var(--main-border-radius);margin:2vw auto 7vw;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;align-content:flex-start;}
    #full div.drow-active {background:var(--color-yel-20);}
    #full div.drow-active div.dcell {border:0;}
    #full div.rhead {display:none;}
    #full div.dcell {position:relative;white-space:nowrap;padding:.5vw 1vw .5vw 2vw;margin-bottom:1vw;border-bottom:none;}
    #full div.dcell:nth-child(1) {width:100%;text-align:left;font-size:var(--font-size-md);color:var(--color-wht-80);border-radius:var(--main-border-radius) var(--main-border-radius) 0 0;background:var(--color-nav-80);}
    #full div.dcell:nth-child(1) span {font-size:.8em;color:var(--color-wht-50);}
    #full div.dcell:nth-child(2) {position:absolute;right:1vw;top:8vw;width:12%;color:var(--color-red);text-align:left;}
    #full div.client  {position:relative !important;right:auto !important;top:auto !important;width:80% !important;color:var(--color-red);text-align:left;}
    #full div.dcell:nth-child(3) {position:absolute;right:.5vw;top:0;width:50%;font-size:var(--font-size-md);text-shadow:var(--text-shadow);color:var(--color-yel-60);text-align:right;}
    #full div.dcell:nth-child(4) {width:90%;color:var(--color-red);text-align:left;}
    #full div.dcell:nth-child(5) {width:90%;}
    #full div.dcell:nth-child(6) {display:none;}
    #full div.dcell:nth-child(7) {width:80%;margin-top:2vw;font-size:var(--font-size-md);color:var(--color-grn);font-weight:500;text-align:left;}
    #full div.dcell:nth-child(2) img {width:100%;height:10vw;}
    #full div.dcell:nth-child(2) img:hover {z-index:2;transform:scale(2);}
    #full div.dcell:nth-child(3) img {width:4vw;height:2.5vw;object-fit:cover;transition:var(--transition-tip);cursor:pointer;}
    #full div.dcell:nth-child(3) img:hover {z-index:2;transform:scale(2);}
    #full div.dcell:nth-child(2) span,
    #full div.dcell:nth-child(4) span,
    #full div.dcell:nth-child(5) span,
    #full div.dcell:nth-child(6) span {color:var(--color-txt-80);}
    #full div.dcell:nth-child(8) {position:absolute;bottom:-1vw;right:.2vw;}
    #full div.dlast {width:16%;display:block !important;margin-top:0vw;}
    #full div.dlast svg {width:80%;margin:0 0 1vw auto;}
    #full div.drow:hover div.dcell {border-bottom:none;}
    #full div.price {padding-top:3vw;}
    #full div.status {padding:.4vw 0;}
    #full div.status aside {left:-2vw;}
    #full div.status:hover aside {left:-10vw;}
    #full button.more {margin:2vw auto;}
    #full button.price {display:none;}

    #order {width:100%;min-width:340px;max-width:800px;margin:0 auto;}
    #order div.head {display:flex;justify-content:space-between;align-items:flex-end;height:8vw;max-height:36px;width:100%;}
    #order div.head h4 {width:45%;margin-bottom:.4vw;text-align:right;font-size:var(--font-size-sx);padding:0 2vw .5vw 0;}
    #order div.head h3 {width:95%;margin-bottom:0;font-size:var(--font-size-sl);}
    #order div.head h3 span {margin:0 .5vw;}
    #order div.body {width:90%;margin:2vw auto 3vw;}
    #order div.body h3 {font-size:var(--font-size-ss);margin:1vw auto 1vw 0;}
    #order div.body div.flex {display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-start;}
    #order div.wrap {width:100%;max-width:500px;}
    #order div.body div.images {margin-top:4vw;justify-content:center;flex-wrap:wrap;align-items:center;}
    #order div.body div.images img {width:95%;margin-bottom:3vw;box-shadow:none;}
    #order div.body div.images img:hover {transform:scale(1);}
    #order ul {margin-bottom:5vw;width:100%;}
    #order ul li {font-size:var(--font-size-sx);margin-bottom:2vw;width:100%;}
    #order ul li b {font-size:var(--font-size-sx);margin:0 0 .4vw auto;}
    #order ul li i {width:100%;height:1vw;margin:0 -50vw .2vw .2vw;}
    #order ul li span {white-space:nowrap;margin-bottom:.1vw;}
    #order ul li.client {font-size:var(--font-size-sl);}
    #order ul b.client {font-weight:500;}
    #order ul.price {margin-top:5vw;}
    #order div.price-total {margin:4vw auto 4vw;}
    #order div.price-total ul li.last {font-size:var(--font-size-md);}
    #order div.price-total ul li.last b {font-size:var(--font-size-lm);}
    #order div.order-range {width:90%;margin:4vw auto;transform:scale(1.2);}
    #order div.order-range div.flex {width:100%;margin:0 auto .5vw;display:flex;flex-wrap:nowrap;justify-content:space-between;}
    #order div.order-range div.flex div {font-size:var(--font-size-sxy);white-space:normal;font-weight:600;text-align:center;color:var(--color-gry-60);}
    #order div.order-range div.flex div span {display:none;}
    #order div.order-range div.flex div svg.arrows {display:none;}

    #users {width:96%;margin:5vw auto;background:none;border-radius:none;box-shadow:none;}
    #users div.dtable {width:95%;font-size:var(--font-size-sy);background:none;box-shadow:none;}
    #users div.drow {position:relative;box-shadow:0px 0px 6px var(--color-drk-80);background:var(--color-wht);border-radius:var(--main-border-radius);margin:2vw auto 7vw;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:flex-end;align-content:flex-start;}
    #users div.rhead {display:none;}
    #users div.dcell {white-space:nowrap;padding:.5vw 1vw;margin-bottom:1vw;border-bottom:none;}
    #users div.dcell:nth-child(1) {width:100%;text-align:left;font-size:var(--font-size-md);color:var(--color-wht-80);border-radius:var(--main-border-radius) var(--main-border-radius) 0 0;background:var(--color-nav-80);}
    #users div.dcell:nth-child(1) span {font-size:.8em;color:var(--color-wht-80);margin-left:2vw;}
    #users div.dcell:nth-child(1) b.today,
    #users div.dcell:nth-child(1) b.yeday {font-size:.7em;margin:0 1vw 0 1vw;color:var(--color-yel);font-weight:600;}
    #users div.dcell:nth-child(2) {position:absolute;right:.5vw;top:0;width:50%;font-size:var(--font-size-sx);text-shadow:var(--text-shadow);color:var(--color-yel-80);text-align:right;}
    #users div.dcell:nth-child(3) {width:60%;overflow:hidden;text-overflow:ellipsis;color:var(--color-txt);text-align:left;}
    #users div.dcell:nth-child(5) {font-size:var(--font-size-sy);}
    #users div.dcell:nth-child(6) {width:50%;font-size:var(--font-size-sx);}
    #users div.dcell:nth-child(6) svg.login {width:4vw;fill:var(--color-drk-80);margin-bottom:-.6vw;}
    #users div.dcell:nth-child(7) {width:20%;}
    #users div.dlast {width:5%;}
    #users div.drow:hover {background:var(--color-yel-40);}
    #users div.drow:hover div.dcell {border-bottom:none;}
    #users div.drow-active {background:var(--color-yel-20);}
    #users div.drow-active div.dcell {border-bottom:none;}
    #users div.status {position:relative;text-align:center;padding:.4vw 0;cursor:pointer;}
    #users div.status aside {opacity:0;left:-2vw;}
    #users div.status:hover aside {opacity:1;left:-10vw;}
    #users div.price {color:var(--color-grn);}
    #users button.more {margin:2vw auto;}

    #users form {width:80%;max-width:80vw;margin:8vw auto 6vw;}
    #users form svg {width:6vw;position:absolute;left:.5vw;top:1vw;}
    #users form input {width:100%;max-width:80vw;padding:2vw 1vw 2vw 8vw;}
    #users form input:focus {border-radius:0 0 0 var(--main-border-radius);}
    #users form input::placeholder {color:var(--color-nav-80) !important;}

    #home {width:100%;min-width:350px;max-width:900px;}
    #home ul {margin:0 auto 3vw;min-width:auto;max-width:320px;display:flex;flex-wrap:wrap;justify-content:space-around;}
    #home ul li {margin:2vw 2vw;padding:3vw 1vw 0;width:auto;min-width:120px;border-radius:5px;}
    #home ul li svg {width:40%;min-width:60px;max-width:100px;margin:3vw auto;}
    #home ul li span {font-size:var(--font-size-lm);display:block;white-space:nowrap;}
    #home div.dtable {margin-top:0;display:block;}
    #home div.drow {display:block;}
    #home div.dcell {display:block;padding-top:0;width:100%;}
    #home button.register {display:block;margin:4vw 15% 5vw;padding:2vw 0;width:70%;max-width:320px;}
    #home button.register svg.register {top:1.5vw;width:6vw;}

    #form-home {margin:0 auto;width:100%;min-width:340px;max-width:900px;padding-bottom:2vw;}
    #form-home div.half-comp-wrap {margin-top:0;}
    #form-home form {width:80%;margin:10vw auto 0vw;}
    #form-home form fieldset {min-width:260px;max-width:380px;}
    #form-home form button {width:80%;min-width:240px;max-width:340px;margin:4vw auto;}
    #form-home form button svg {top:1.7vw;}
    #form-home div.flex {justify-content:center;flex-wrap:wrap;flex-direction:column-reverse;}
    #form-home div.flex a {font-size:var(--font-size-lm);white-space:nowrap;margin:4vw;}
    
}
