

/* ------------  HEADER ------------- */
header {position:fixed;left:0;right:0;top:0;height:8vw;z-index:10;display:flex;justify-content:space-between;align-self:flex-start;background:var(--color-blh-30);box-shadow:0px 3px 60px var(--color-nav-60);transition:var(--transition-head);}
header button,
header input {border-color:var(--color-txt-80);color:var(--color-txt-80);}
header button svg {fill:var(--color-txt-80);}
header .toggler {display:none;}

nav {position:fixed;left:0;right:0;top:8vw;height:3.6vw;max-height:80px;z-index:10;display:flex;justify-content:center;align-items:flex-start;background:var(--color-nav);box-shadow:-1px 0 36px var(--color-drk);transition:var(--transition-head);}
nav a:link,
nav a:visited {white-space:nowrap;border-bottom:solid 0px var(--color-wht-20);color:var(--color-wht-60);font-size:var(--font-size-sy);padding:.5vw 2.5vw .4vw;margin:.3vw .5vw .5vw;text-shadow:var(--blck-shadow);text-transform:uppercase;text-decoration:none;}
nav a:hover {border-bottom:solid 0px var(--color-yel-80);color:var(--color-yel-80);}
nav a.active {border-bottom:solid 0px var(--color-yel-80);color:var(--color-yel-80);text-shadow:var(--blck-shadow);}

#logo {position:fixed;top:1.2vw;left:3.6vw;cursor:pointer;}
#logo img {height:5vw;}

#user {z-index:10;position:fixed;top:1vw;right:3vw;}
#user button {z-index:1;padding:.6vw 5vw .5vw 1.5vw;text-shadow:var(--blck-shadow);width:100%;width:15vw;border:0;border-right:solid 3px var(--color-nav-60);border-bottom:solid 3px var(--color-nav-60);color:var(--color-wht-80);background:var(--color-red);text-shadow:0px 0px 6px var(--color-txt-60);}
#user button svg {fill:var(--color-wht-80);width:1.6vw;top:.52vw;right:1vw;}
#user button:hover {color:var(--color-yel-80);padding:.6vw 5.5vw .5vw 1vw;}
#user button:hover svg {fill:var(--color-yel-80);right:.5vw;}
#user button.false {z-index:-1;}
#user ul {z-index:-1;display:flex;justify-content:space-between;margin:-3vw auto 0;}
#user ul li {display:inline-block;padding:4px 16px;margin:.5vw 1.5vw 0 auto;font-size:var(--font-size-sw);text-shadow:0px 0px 3px var(--color-yel-60);font-weight:400;text-transform:uppercase;color:var(--color-wht-80);background:var(--color-grn);box-shadow:0px 0px 9px var(--color-txt-80);border-radius:var(--field-border-radius);cursor:pointer;}
#user ul li:hover {color:var(--color-yel-80);}
#user ul li.full {color:var(--color-wht-80);background:var(--color-gry);box-shadow:0px 0px 9px var(--color-txt-80);margin-right:0;}
#user ul li.full:hover {color:var(--color-yel-80);}

#search {z-index:10;position:fixed;top:1vw;right:30vw;width:25vw;}
header.active #search {z-index:10;position:fixed;top:1vw;right:45vw;width:20vw;} 
#search form {width:100%;position:relative;}
#search form svg {width:1.4vw;position:absolute;left:.4vw;top:.4vw;fill:var(--color-nav-80);cursor:pointer;}
#search form svg:hover {fill:var(--color-grn);}
#search form input {border-radius:0 0 0 8px;width:100%;max-width:40vw;font-size:var(--font-size-sxx) !important;background:var(--color-gry-20);padding:.5vw .5vw .5vw 2.5vw;box-shadow:0px 0px 9px var(--color-wht-60);border-top:solid 2px var(--color-nav);border-right:solid 2px var(--color-nav);}
#search form input:focus {border-radius:0 0 0 8px;box-shadow:none;background:var(--color-yel-40);}
#search form input::placeholder {color:var(--color-nav-80) !important;}
#search table {font-size:var(--font-size-sxx);border-spacing:0;}
#search table td {color:var(--color-gry-90);white-space:nowrap;padding:1vw;line-height:85%;vertical-align:middle;}
#search table td b {color:var(--color-grn);text-transform:uppercase;font-weight:500;}

#login {position:fixed;top:1.5vw;right:20vw;width:22vw;}
#login table {font-size:var(--font-size-sx);border-spacing:0;}
#login table td {color:var(--color-gry-90);white-space:nowrap;padding-right:1vw;line-height:85%;vertical-align:middle;}
#login table td b {color:var(--color-wht-90);padding-right:1vw;text-transform:uppercase;font-weight:500;}
#login table th {text-align:left;padding-right:1vw;vertical-align:top;color:var(--color-txt-80);font-weight:200;}
#login table th:last-child {padding-right:0;}
#login span {color:var(--color-red);text-decoration:underline;cursor:pointer;}
#login span:hover {color:var(--color-red-80);text-decoration:none;}
#login u.gry {font-weight:200 !important;text-decoration:none !important;margin-left:2vw;}

#hello {z-index:200;position:fixed;top:4.3vw;left:45vw;width:20vw;}
#hello h4 {position:relative;color:var(--color-txt-80);font-size:var(--font-size-sx);margin-bottom:.4vw;}
#hello h4.anon {margin-top:.7vw;}
#hello h4 span {position:absolute;right:3.5vw; bottom:2vw;color:var(--color-red-90);cursor:pointer;}
#hello h4 b u {color:var(--color-red-90);text-transform:capitalize;text-decoration:none;}

#rate {position:relative;opacity:.5;z-index:300;position:fixed;top:4.8vw;left:24vw;width:40vw;display:flex;flex-wrap:wrap;align-items:flex-end;color:var(--color-yel-80);transition:var(--transition-tip);}
#rate:hover {opacity:.9;}
#rate div {position:relative;opacity:.8;padding:.8vw 1vw .3vw .8vw;margin:0 .5vw .4vw 0;min-width:2vw;font-size:var(--font-size-sm);line-height:80%;background:var(--color-txt-20);color:var(--color-txt-80);}
#rate div.rub {padding:.8vw 1.7vw .3vw .8vw;}
#rate div.kzt {padding:.8vw 2.1vw .3vw .8vw;}
#rate div span {position:absolute;right:.8vw; bottom:.1vw;font-size:.6em;font-weight:500;}
#rate svg {position:absolute;top:.15vw;right:.5vw;font-size:var(--font-size-sm);}
#rate svg.plus {width:.8vw;fill:var(--color-grn);}
#rate svg.minus {width:.8vw;fill:var(--color-red);}
#rate div.last {position:absolute;top:-3.5vw;left:-18vw;opacity:.7;height:1.2vw;font-size:var(--font-size-sxx);width:5vw;background:transparent;color:var(--color-nav);box-shadow:none;padding:.5vw 2vw .3vw 1vw;cursor:pointer;transition:var(--transition-tip);}
#rate div.last-active {top:-4.5vw !important;}
#rate div:hover {opacity:.9;background:var(--color-wht-40);color:var(--color-txt-80);}
#rate div.day-now {position:relative;width:4vw;box-shadow:none;}
#rate div.day-now span.date {font-size:var(--font-size-sxx);color:var(--color-wht-80);text-shadow:var(--blck-shadow);position:absolute;left:-1.75vw; bottom:0;width:18px;font-weight:600;padding:.5vw .4vw;}
#rate div.day-now b.day {position:absolute;left:.9vw;bottom:.5vw;transform:scale(1.1);color:var(--color-nav);text-shadow:none;font-weight:300;}
#rate div.day-green {border-left:solid 1.8vw var(--color-grn);border-bottom:solid 1px var(--color-gry-90);border-radius:var(--field-border-radius);} 
#rate div.day-red {border-left:solid 1.8vw var(--color-red);} 
#rate form {position:absolute;display:flex;flex-wrap:nowrap;align-items:center;top:-15vw;max-width:420px;background:var(--color-wht);border-radius:var(--field-border-radius);transition:var(--transition-tip);}
#rate form.form-active {top:-2.5vw;}
#rate form fieldset {width:22%;min-width:100px;max-width:90px;height:3.7vw;}
#rate form fieldset input {padding:1.5vw 2% .3vw;}
#rate form button {width:20%;min-width:30px;max-width:40px;text-align:center;padding:5px;margin-right:2vw;}
#rate form button svg {width:26px;position:relative;margin:0;right:auto;left:auto;}

#info {opacity:.5;position:fixed;top:5vw;right:3vw;width:33vw;display:flex;justify-content:space-between;align-content:center;align-items:center;}
#info:hover {opacity:.9;}
#info svg.flag {width:2vw;opacity:.8;}
#info svg.arrows {fill:var(--color-txt-40);width:2vw;}
#info figure {display:flex;justify-content:space-between;align-items:center;align-content:center;position:relative;width:8vw;}
#info figcaption {display:flex;justify-content:space-between;align-content:flex-end;align-items:flex-end;margin-top:.4vw;;width:5vw;font-family:var(--font-time);color:var(--color-red);font-weight:500;white-space:nowrap;position:absolute;right:0;top:0;font-size:var(--font-size-sy);transform:scale(1.2);text-shadow:0px 0px 3px var(--color-wht);}
#info span {display:block;position:absolute;left:100%;bottom:3px;width:.6vw;;min-width:16px;max-width:20px;font-size:.7em;padding:0 2px 0 2px;border-radius:1px;margin:-.6px auto 0 .6vw;text-shadow:none;font-weight:600;}


header.active #rate div:last-child {position:absolute;top:-3.5vw;left:0vw;}

/* ------------ END HEADER ------------- */


footer {font-family:var(--font-bold);position:fixed;left:0;right:0;bottom:0;height:2vw;z-index:10;background:var(--color-slv);box-shadow:1px 0 9px var(--color-drk);color:var(--color-wht-60);}
footer div.flex {display:flex;justify-content:space-between;width:96%;margin:.3vw auto;}
footer aside.copyright {font-size:var(--font-size-sxx);padding-top:0;}
footer aside.others {font-size:var(--font-size-sx);}







@media (min-width:1600px){
    header {position:fixed;left:0;right:0;top:0;height:8vw;z-index:10;}

    nav {position:fixed;left:0;right:0;top:8vw;height:3vw;}
}







/*----------------------------- СРЕДНИЕ ЭКРАНЫ [ 1100 px ] -------------------------------------*/

@media (max-width:1100px){
    nav {height:4vw;}
    nav a:link,
    nav a:visited {font-size:var(--font-size-sl);padding:.5vw 2.5vw .4vw;margin:.3vw 3vw 0vw;}

    #search {z-index:10;position:fixed;top:1vw;right:25vw;width:30vw;}
    header.active #search {z-index:10;position:fixed;top:1vw;right:25vw;width:35vw;} 
    #search form {width:100%;position:relative;}
    #search form svg {width:1.7vw;position:absolute;left:.4vw;top:.5vw;}
    #search form input {border-radius:0 0 0 8px;width:100%;max-width:40vw;padding:.5vw .5vw .5vw 2.5vw;}
    #search form input:focus {border-radius:0 0 0 8px;box-shadow:none;}
    #search form input::placeholder {color:var(--color-nav-80) !important;}
    #search table {font-size:var(--font-size-sxx);border-spacing:0;}
    #search table td {white-space:nowrap;padding:1vw;line-height:85%;vertical-align:middle;}
    #search table td b {text-transform:uppercase;font-weight:500;}
    
    #login {display:none;}

    #hello {z-index:200;position:fixed;top:4.3vw;left:45vw;width:20vw;}
    #hello h4 span {position:relative;right:-3vw; bottom:-1vh;font-size:var(--font-size-sl);}
    #hello h4 b {display:none;}
   
    
    footer {height:2.5vh;}
    footer aside.copyright {font-size:var(--font-size-sl);}
    footer aside.others {font-size:var(--font-size-sl);}

}    


/*----------------------------- МАЛЫЕ ЭКРАНЫ [ 800 px ] -------------------------------------*/

@media (max-width:800px){
    header {background:var(--color-wht);position:fixed;left:0;right:0;top:0;height:18vw;z-index:200;display:flex;justify-content:space-between;align-self:flex-start;}
    header div.bg {background:var(--color-blh-30);position:absolute;top:0;left:0;right:0;bottom:0;}
    header .toggler {display:block;width:32px;height:20px;position:absolute;right:5vw;top:-2vw;margin:30px auto;transform:rotate(0deg);transition: .5s ease-in-out;cursor:pointer;}
    header .toggler span {display:block;position:absolute;height:4px;width:100%;background:var(--color-nav-80);border-radius:10px;opacity:1;left:0;transform:rotate(0deg);transition:var(--transition-tip);}
    header .toggler span:nth-child(1) {top:0;}
    header .toggler span:nth-child(2) {top:10px;}
    header .toggler span:nth-child(3) {top:20px;}
    header .toggler-open span:nth-child(1) {top:10px;transform:rotate(135deg);}
    header .toggler-open span:nth-child(2) {opacity:0;left:-32px;}
    header .toggler-open span:nth-child(3) {top:10px;transform:rotate(-135deg);}

    #logo {position:fixed;top:2vw;left:3.6vw;cursor:pointer;}
    #logo img {height:13vw;}

    nav {position:fixed;left:0;right:auto;top:7vh;height:92vh;max-height:1000px;z-index:150;display:flex;flex-wrap:wrap;justify-content:center;align-content:flex-start;align-items:flex-start;transition:var(--transition-tip);}
    nav.close {left:0;top:-100vh;right:auto;}
    nav a:link,
    nav a:visited {width:100%;border-bottom:solid 1px var(--color-wht-20);font-size:var(--font-size-md);padding:2vh 5vw;margin:0 auto;}
    nav a:hover {border-bottom:solid 1px var(--color-yel-80);}
    nav a.active {border-bottom:solid 1px var(--color-yel-80);}
    nav a:first-child {margin-top:3vh;}

    #login {display:block;background:var(--color-blh-20);position:relative;top:auto;right:auto;width:100%;}
    #login table {font-size:var(--font-size-sm);width:auto;min-width:280px;margin:2vh auto;}
    #login table td {text-align:right;width:10%;color:var(--color-wht-80);}
    #login table td b {color:var(--color-yel);padding-right:1vw;text-transform:uppercase;font-weight:500;}
    #login table th b.red {color:var(--color-yel) !important;font-weight:300;}
    #login table th {text-align:left;width:auto;white-space:nowrap;padding-left:4vw;width:20%;vertical-align:middle;color:var(--color-yel-80);font-size:var(--font-size-lm);}
    #login table th:last-child {padding-right:0;}
    #login u.gry {color:var(--color-wht-80);font-weight:200 !important;text-decoration:none !important;margin-left:2vw;}
    
    #hello {position:relative;top:auto;left:auto;width:100%;margin-top:0;}
    #hello h4 {text-transform:capitalize;width:80%;min-width:340px;margin:2vh auto;position:relative;color:var(--color-yel-80);font-size:var(--font-size-sm);}
    #hello h4.anon {margin-top:2vw;}
    #hello h4 span {position:relative;text-transform:none;right:auto;bottom:auto;float:right;bottom:4vw;color:var(--color-wht);font-size:var(--font-size-lm);}

    #search {background:var(--color-wht-80);position:relative;top:auto;right:auto;width:100%;}
    #search form {width:80%;margin:2vh auto 0;}
    #search form svg {width:8vw;position:absolute;left:1vw;top:1.5vw;}
    #search form input {width:100%;max-width:80vw;padding:3vw 2vw 2vw 12vw;}
    #search table {display:none;}

    #rate {background:var(--color-wht-80);opacity:1;position:relative;top:auto;left:auto;width:100%;display:flex;flex-wrap:nowrap;justify-content:space-around;}
    #rate:hover {opacity:1;}
    #rate div {position:relative;opacity:1;padding:1vh 1.5vw .5vh 2vw;margin:2vh 1vw 2vh;width:16%;font-size:var(--font-size-md);line-height:80%;color:var(--color-txt);}
    #rate div.rub {padding:1vh 1.5vw .5vh 2vw;}
    #rate div.kzt {padding:1vh 1.5vw .5vh 2vw;}
    #rate div span {position:relative;right:auto; bottom:auto;font-size:.7em;font-weight:500;}
    #rate svg {position:absolute;top:.3vh;right:.5vw;}
    #rate svg.plus {width:4vw;}
    #rate svg.minus {width:4vw;}
    #rate div.last, #rate form {display:none;}
    
    #info {opacity:1;position:relative;top:auto;right:auto;margin:3vh auto 3vh 5vw;width:80%;display:flex;justify-content:space-between;align-content:center;align-items:center;}
    #info:hover {opacity:1;}
    #info svg.flag {width:6vw;opacity:.8;}
    #info svg.arrows {display:none;}
    #info figure {display:flex;justify-content:space-between;align-items:center;align-content:center;position:relative;width:13vw;}
    #info figcaption {display:flex;justify-content:space-between;align-content:flex-end;align-items:flex-end;margin-top:.7vh;;width:5vw;color:var(--color-wht);font-size:var(--font-size-mw);transform:scale(1.2);text-shadow:none;}
    #info span {display:block;position:absolute;left:100%;bottom:3px;width:.6vw;;min-width:16px;max-width:20px;font-size:.7em;padding:0 2px 0 2px;border-radius:1px;margin:-.6px auto 0 .6vw;text-shadow:none;font-weight:600;}
            
    #user {position:relative;top:auto;right:auto;}
    #user button {padding:1vh 5vw 1vh 0;font-size:var(--font-size-md);min-width:70vw;}
    #user button svg {width:4vw;top:1.2vh;right:2vw;}
    #user button:hover {padding:1vh 5vw 1vh 0;}
    #user button:hover svg {fill:var(--color-yel-80);right:.5vw;}
    #user ul {z-index:-1;display:flex;justify-content:space-between;margin:-3vw auto 0;}
    #user ul li {padding:15px;margin-right:5vw;font-weight:500;background:var(--color-grn-80);font-size:var(--font-size-md);}

    footer {height:5vw;position:relative;}
    footer div.flex {display:flex;flex-wrap:nowrap;justify-content:space-around;width:100%;margin:0 auto 0;}
    footer aside.copyright {font-size:var(--font-size-sxx);}
    footer aside.others {font-size:var(--font-size-sxx);}
}