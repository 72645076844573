@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Podkova:wght@400..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Doodle+Shadow&family=Rubik+Spray+Paint&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Ubuntu+Condensed&display=swap');
@font-face {
  font-family:'Time_oClock';
  src: url('../fonts/CursedTimerUlil.ttf');
  font-weight: normal;
  background-color:#000;
  font-style: normal;
}
@font-face {
  font-family:'Valencia';
  src: url('../fonts/Valencia script One.ttf');
  font-weight: normal;
  background-color:#000;
  font-style: normal;
}
:root {
    --color-bg:#f5c0c7;
    --color-bg-2:#f9eae9;
    --color-txt:rgba(16, 22, 70, 1);
    --color-txt-80:rgba(16, 22, 70, .8);
    --color-txt-60:rgba(16, 22, 70, .6);
    --color-txt-40:rgba(16, 22, 70, .4);
    --color-txt-30:rgba(16, 22, 70, .3);
    --color-txt-20:rgba(16, 22, 70, .2);
    --color-drk:rgba(2, 4, 20, 1);
    --color-drk-90:rgba(2, 4, 20, .9);
    --color-drk-80:rgba(2, 4, 20, .8);
    --color-drk-70:rgba(2, 4, 20, .7);
    --color-drk-60:rgba(2, 4, 20, .5);
    --color-drk-40:rgba(2, 4, 20, .4);
    --color-drk-20:rgba(2, 4, 20, .2);
    --color-gry:rgb(88, 88, 88);
    --color-gry-90:rgba(88, 88, 88,.9);
    --color-gry-80:rgba(88, 88, 88,.8);
    --color-gry-60:rgba(88, 88, 88,.6);
    --color-gry-40:rgba(88, 88, 88,.4);
    --color-gry-20:rgba(88, 88, 88,.2);
    --color-slv: rgba(84, 92, 113, 1);
    --color-slv-90: rgba(84, 92, 113, .9);
    --color-slv-80: rgba(84, 92, 113, .8);
    --color-slv-70: rgba(84, 92, 113, .7);
    --color-slv-60: rgba(84, 92, 113, .6);
    --color-slv-50: rgba(84, 92, 113, .5);
    --color-slv-40: rgba(84, 92, 113, .4);
    --color-slv-30: rgba(84, 92, 113, .3);
    --color-slv-20: rgba(84, 92, 113, .2);
    --color-slv-10: rgba(84, 92, 113, .1);
    --color-wht:#fcf9f9;
    --color-wht-90:rgba(252, 249, 249, .9);
    --color-wht-70:rgba(252, 249, 249, .7);
    --color-wht-80:rgba(252, 249, 249, .8);
    --color-wht-60:rgba(252, 249, 249, .6);
    --color-wht-40:rgba(252, 249, 249, .4);
    --color-wht-30:rgba(252, 249, 249, .3);
    --color-wht-20:rgba(252, 249, 249, .2);
    --color-pnk:rgba(206, 97, 152,1);
    --color-pnk-80:rgba(206, 97, 152,.8);
    --color-pnk-60:rgba(206, 97, 152,.6);
    --color-pnk-40:rgba(206, 97, 152,.4);
    --color-sun:rgba(248,128,33,1);
    --color-sun-80:rgba(248,128,33,.8);
    --color-sun-60:rgba(248,128,33,.6);
    --color-sun-40:rgba(248,128,33,.4);
    --color-red:rgba(151, 46, 63,1);
    --color-red-90:rgba(151, 46, 63,.9);
    --color-red-80:rgba(151, 46, 63,.8);
    --color-red-70:rgba(151, 46, 63,.7);
    --color-red-60:rgba(151, 46, 63,.6);
    --color-red-50:rgba(151, 46, 63,.5);
    --color-red-40:rgba(151, 46, 63,.4);
    --color-red-30:rgba(151, 46, 63,.3);
    --color-red-20:rgba(151, 46, 63,.2);
    --color-clk:rgb(187, 0, 0);
    --color-grn:rgba(0, 117, 45,1);
    --color-grn-90:rgba(0, 117, 45,.9);
    --color-grn-80:rgba(0, 117, 45,.8);
    --color-grn-60:rgba(0, 117, 45,.6);
    --color-grn-40:rgba(0, 117, 45,.4);
    --color-grn-30:rgba(0, 117, 45,.3);
    --color-grn-20:rgba(0, 117, 45,.2);
    --color-yel:rgba(255, 251, 0, 1);
    --color-yel-80:rgba(255, 251, 0, .8);
    --color-yel-60:rgba(255, 251, 0, .6);
    --color-yel-40:rgba(255, 251, 0, .4);
    --color-yel-30:rgba(255, 251, 0, .3);
    --color-yel-20:rgba(255, 251, 0,.2);
    --color-yel-10:rgba(255, 251, 0,.15);
    --color-blh:rgba(0, 81, 187, 1);
    --color-blh-90:rgba(0, 81, 187, .9);
    --color-blh-80:rgba(72, 136, 219, 0.8);
    --color-blh-60:rgba(0, 81, 187, .6);
    --color-blh-40:rgba(0, 81, 187, .4);
    --color-blh-30:rgba(0, 81, 187, .3);
    --color-blh-20:rgba(0, 81, 187, .2);
    --color-blu:rgba(95, 134, 184, 1);
    --color-blu-80:rgba(95, 134, 184, .8);
    --color-blu-60:rgba(231, 238, 247, 0.6);
    --color-blu-40:rgba(95, 134, 184, .4);
    --color-blu-30:rgba(95, 134, 184, .3);
    --color-blu-20:rgba(95, 134, 184, .2);
    --color-nav:rgba(57, 47, 100, 1);
    --color-nav-90:rgba(57, 47, 100,.9);
    --color-nav-80:rgba(57, 47, 100,.8);
    --color-nav-60:rgba(57, 47, 100,.6);
    --color-nav-40:rgba(57, 47, 100,.4);
    --color-nav-20:rgba(57, 47, 100, 0.2);
    --color-lbg:rgb(231, 231, 231);

    --font-capt:"Roboto", sans-serif;
    --font-logo:"Rubik Doodle Shadow", system-ui;
    --font-bold:"Podkova", serif;
    --font-main:"Ubuntu Condensed", sans-serif;
    --font-head:"Source Sans 3", sans-serif;
    --font-time:"Time_oClock";
    --font-ibm:"IBM Plex Mono", sans-serif;
    --font-crr:"IBM Plex Mono", monospace;

    --font-size-sxy:1vw;
    --font-size-sxx:1vw;
    --font-size-sx:1.1vw;
    --font-size-sw:1.25vw;
    --font-size-sy:1.35vw;
    --font-size-sm:1.4vw;
    --font-size-sw:1.5vw;
    --font-size-sl:1.6vw;
    --font-size-ss:1.8vw;
    --font-size-ms:2vw;
    --font-size-ml:2.1vw;
    --font-size-md:2.3vw;
    --font-size-lm:2.5vw;
    --font-size-lg:2.8em;
    --font-size-lw:3em;
    --font-size-lx:3.2vw;

    --transition: all .3s ease 0s;
    --transition-l: all .6s ease 0s;
    --transition-head: all .7s ease-out 0s;
    --transition-but: all .2s ease-out 0s;
    --transition-tip: all .2s ease-in 0s;
    --main-border-radius: 6px;
    --field-border-radius:4px;
    --text-shadow:0px 0px 3px var(--color-blk);
    --blck-shadow:0px 0px 9px #000;
    --svg-shadow:drop-shadow( 3px 3px 2px rgba(0, 0, 0, .6));
    --text-shadow-yel:0px 0px 9px var(--color-yel);
    --dark-but-shadow:0px 0px 100px var(--color-yel-20), 0px 0px 20px var(--color-nav-80), 1px 3px 60px var(--color-nav-60) inset;
    --block-shadow:0px -3px 126px var(--color-nav-40);
    --block-shadow-a:0px 0px 60px var(--color-txt-80);
    --lght-shadow:0px -3px 16px var(--color-nav-20);
}

::placeholder {color:var(--color-txt-80);}
::-webkit-scrollbar {width:.6vw;background-color:var(--color-blu);}
::-webkit-scrollbar-thumb {background-color:var(--color-nav);}

* {margin:0;padding:0;}
body {font-size:var(--font-size-sy);color:var(--color-wht-70);font-family:var(--font-main);overflow-x: hidden;}
header, footer, section, article, nav {display:block;width:100%;}
h1 {font-size:var(--font-size-lm);font-family:var(--font-head);color:var(--color-nav);text-shadow:0px 0px 36px var(--color-yel-60);text-align:left;text-transform:uppercase;margin:0;}
h1 span {color:var(--color-red);}
h2 {font-size:var(--font-size-md);color:var(--color-red);font-family:var(--font-capt);text-align:left;margin:2vh auto 3vh;}
h3 {text-transform:uppercase;color:var(--color-red);font-size:var(--font-size-sm);}
h4 {color:var(--color-drk-90);font-weight:300;font-size:var(--font-size-sl);}
h3 span {color:var(--color-txt-80);}
p {line-height:130%;color:var(--color-drk-80);}
p span {color:var(--color-red);font-weight:800;}
p.request-result {text-align:center;margin:3vw auto 0;font-size:var(--font-size-sl);}
pre {font-size:var(--font-size-lg);text-align:center;margin:30px auto;}
a:link,
a:visited {color:var(--color-red);text-decoration:none;}
a:hover {color:var(--color-red-80);text-decoration:none;}

.dtable {display:table;margin:auto;}
.dhead {display:table-cell;font-family:var(--font-bold);color:var(--color-txt-80);text-transform:uppercase;font-weight:600;padding:0 0 1vw;}
.drow {display:table-row;}
.dcell {display:table-cell;}
.relative {position:relative;}
.hidden {display:none;}
.white {color:var(--color-wht-80);}
.red {color:var(--color-red);}
.grn {color:var(--color-grn);}
.gry {color:var(--color-gry);}
.drk {color:var(--color-drk-90);}
.align-center {text-align:center !important; }
.align-left {text-align:left !important;}
.align-right {text-align:right !important;}
.loading {width:70px;height:60px;background:#ccc center no-repeat;background-size:100% 100%;}

.mod-comp-wrap {border-radius:var(--main-border-radius);box-shadow:var(--block-shadow);margin:3vw auto 0;background:var(--color-wht);}
.top-comp-wrap {border-radius:var(--main-border-radius);box-shadow:var(--block-shadow);margin:3vw auto 0;padding-bottom:2vh;}
.half-comp-wrap {border-radius:var(--main-border-radius);box-shadow:var(--block-shadow);margin:3vw auto 0;padding-bottom:2vh;}

.nobg {background:none;border-radius:none;box-shadow:none;}
div.flex {position:relative;display:flex;justify-content:space-between;align-items:center;}

.App {position:relative;width:100vw;height:100vh;overflow:hidden;}

.modal {display:none;position:absolute;opacity:0;left:0;right:0;top:12vw;bottom:5vw;transition:var(--transition-tip);}
.modal-active nav, .modal-active header {opacity:0;}
.modal-active {z-index:2000;display:block;opacity:1;position:fixed;top:0;left:0;right:0;bottom:0;background:var(--color-blu-40);}
.modal-active div.close {z-index:2100;position:fixed;right:2%;bottom:5vh;cursor:pointer;}
.modal-active div.close svg {fill:var(--color-red);width:5vw;filter:var(--svg-shadow);}
.modal-active div.displ {position:fixed;top:0;left:0;right:0;bottom:2.5vw;overflow:hidden;overflow-y:auto;}

aside.tooltip {font-family:var(--font-head);position:absolute;left:-9vw;top:0;background:var(--color-blu);color:var(--color-wht-80);box-shadow:var(--dark-but-shadow);padding:9px 16px;font-size:var(--font-size-sy);border-radius:var(--main-border-radius);white-space:nowrap;transition:var(--transition-tip);}
aside.tooltip span {color:var(--color-yel);}
aside.tooltip svg {display:none;z-index:-1;position:absolute;right:-1.4vw;top:-2px;width:3vw;fill:var(--color-blu);}


/*----------------------------- СРЕДНИЕ ЭКРАНЫ [ 1400, 1200 px ] -------------------------------------*/


@media (min-width:1600px){
  :root {
  --font-size-sxy:.8vw;
  --font-size-sxx:.9vw;
  --font-size-sx:1vw;
  --font-size-sw:1.05vw;
  --font-size-sy:1.1vw;
  --font-size-sm:1.15vw;
  --font-size-sl:1.2vw;
  --font-size-ss:1.25vw;
  --font-size-ms:1.3vw;
  --font-size-ml:1.4vw;
  --font-size-md:1.5vw;
  --font-size-lm:1.7vw;
  --font-size-lg:1.8em;
  --font-size-lw:2em;
  --font-size-lx:2.2vw;

}
  .App {position:relative;top:11vw;bottom:auto;width:100vw;height:auto;overflow:auto;overflow-x:hidden;}

}

@media (max-width:1100px){
  body {font-size:var(--font-size-sl);}
  header, footer, section, article, nav {display:block;width:100%;}
  h1 {font-size:var(--font-size-lm);margin:0;}
  h1 span {color:var(--color-red);}
  h2 {font-size:var(--font-size-md);margin:2vh auto 3vh;}
  h3 {font-size:var(--font-size-ss);}
  h4 {color:var(--color-drk-90);font-weight:300;font-size:var(--font-size-sl);}
  h3 span {color:var(--color-txt-80);}
  p {line-height:130%;}
  p span {color:var(--color-red);font-weight:800;}
  p.request-result {text-align:center;margin:5vh auto 0;font-size:var(--font-size-sl);}
  pre {font-size:var(--font-size-lg);text-align:center;margin:30px auto;}

  
  .dtable {display:table;margin:auto;}
  .dhead {display:table-cell;padding:0 0 1vw;}
  .drow {display:table-row;}
  .dcell {display:table-cell;}
  .relative {position:relative;}

  .mod-comp-wrap {margin:2.5vh auto 2vh;}
  .top-comp-wrap {margin:2.5vh auto 2vh;}
  .half-comp-wrap {margin:2.5vh auto;}
  
  .nobg {background:none;border-radius:none;box-shadow:none;}
  div.flex {position:relative;display:flex;justify-content:space-between;align-items:center;}
  
  .App {position:relative;width:100vw;height:100vh;overflow:hidden;}
  
  .modal {display:none;position:absolute;opacity:0;left:0;right:0;top:12vw;bottom:5vw;transition:var(--transition-tip);}
  .modal-active nav, .modal-active header {opacity:0;}
  .modal-active {display:block;opacity:1;position:fixed;top:0;left:0;right:0;bottom:0;background:var(--color-blu-40);}
  .modal-active div.close {position:fixed;right:2%;bottom:5vh;cursor:pointer;}
  .modal-active div.close svg {fill:var(--color-red);width:5vw;filter:var(--svg-shadow);}
  .modal-active div.displ {position:fixed;top:0;left:0;right:0;bottom:2.5vw;overflow:hidden;overflow-y:auto;}
  
  aside.tooltip {font-family:var(--font-head);position:absolute;left:-9vw;top:0;background:var(--color-blu);color:var(--color-wht-80);box-shadow:var(--dark-but-shadow);padding:9px 16px;font-size:var(--font-size-sy);border-radius:var(--main-border-radius);white-space:nowrap;transition:var(--transition-tip);}
  aside.tooltip span {color:var(--color-yel);}
  aside.tooltip svg {display:none;z-index:-1;position:absolute;right:-1.4vw;top:-2px;width:3vw;fill:var(--color-blu);}
  
}


/*----------------------------- МАЛЫЕ ЭКРАНЫ [ 800 px ] -------------------------------------*/

@media (max-width:800px){
  :root {
     --font-size-sxy:2.5vw;
    --font-size-sxx:3vw;
    --font-size-sx:4vw;
    --font-size-sw:1.25vw;
    --font-size-sy:4vw;
    --font-size-sm:4.5vw;
    --font-size-sl:4vw;
    --font-size-ss:4vw;
    --font-size-mx:3vw;
    --font-size-mw:3.5vw;
    --font-size-md:4.5vw;
    --font-size-lm:5vw;
    --font-size-lg:2.8em;
    --font-size-lw:3em;
    --font-size-lx:3.2vw;

    --font-capt:"Roboto", sans-serif;
    --font-logo:"Rubik Doodle Shadow", system-ui;
    --font-ibm:"Roboto", sans-serif;
    --font-crr:"Roboto", sans-serif;	

    --block-shadow:none;
    --block-shadow-a:none;
    --main-border-radius: 0px;
  }

  body {font-size:var(--font-size-sl);overflow-x:hidden;}
  h4 {color:var(--color-wht-80);font-weight:300;font-size:var(--font-size-sl);}
  p.request-result button {max-width:200px !important;}
  
  .App {position:relative;top:18vw;bottom:auto;width:100vw;height:auto;overflow:auto;overflow-x:hidden;}

  .dtable {display:block;}
  .dhead {display:block;}
  .drow {display:block;}
  .dcell {display:block;}
  div.flex {position:relative;display:flex;flex-wrap:wrap;justify-content:center;align-items:center;}

  .modal {bottom:0;}
  .modal-active {overflow-y:auto;background:var(--color-lbg);}
  .modal-active div.close {position:fixed;right:0;bottom:0;}
  .modal-active div.close svg {width:15vw;}
  .modal-active div.displ {bottom:0;overflow:hidden;overflow-y:auto;}
  
  .mod-comp-wrap {margin:0 auto 6vw;background:var(--color-wht);box-shadow:none;}
  .top-comp-wrap {margin:0 auto 6vw;background:var(--color-wht);box-shadow:none;}
  .half-comp-wrap {margin:0 auto 6vw;background:var(--color-wht);box-shadow:none;}
  
}